@use 'sass:math';

.authentication-popup {
    font-size: math.div(16rem, 14);

    .modal-inner-wrap {
        border-radius: $border-radius__xs;
    }

    .action-close {
        padding: 20px;
    }

    .fieldset {
        padding: 10px 0;

        input {
            border-radius: $border-radius__xs;
        }
    }

    .actions-toolbar {
        margin: 0;
        padding: 14px 0 28px 0;

        .action-login {
            @include cblys-re-green-btn;
            border-radius: $border-radius__l;
        }

        div.secondary {
            padding-top: 3px;
        }
    }
}
