@use 'sass:math';

.checkout-cart-index-version-1 {
    height: 100%;

    .cart.table-wrapper {
        .cart.item {
            .item-info {
                grid-template-areas: "image details quantity subtotal";
                grid-template-rows: auto;
                position: static;
            }

            .col.item {
                .product-item-name {
                    margin-bottom: 0.15rem;
                }
            }

            .col.qty {
                padding: 0 24px 0 0;

                .energy-label {
                    margin-bottom: 24px;

                    a {
                        order: -1;
                    }
                }
            }

            .col.subtotal {
                padding: 0;

                .old-price .price {
                    font-size: math.div(12rem, 14);
                }

                :not(.old-price) > .price {
                    font-size: 1rem;
                    font-weight: bold;
                }
            }
        }
    }

    .summary {
        &.title {
            padding-left: 0.7rem;
        }
    }

    .cart-container {
        input {
            border-radius: 0;
        }

        .checkout-methods-items {
            margin: $indent__base 0 0 0;

            .action.primary {
                border-radius: 0;
                width: calc(100% - 20px);
            }
        }

        .cart-summary {
            border-radius: 0;
            box-shadow: 0 4px 4px rgba(0,0,0,.25);
            float: right;
            padding: 24px 14px;
            width: 33%;

            .block.discount {
                margin: 0 0 10px 0;

                .title {
                    > strong {
                        margin: 1.5rem 0 10px 0;
                        padding-left: 0.7rem;
                    }
                }

                .fieldset.coupon {
                    padding: 0.5rem 0.5rem 0;

                    input {
                        border: 1px solid $cblys__black;
                    }

                    .actions-toolbar {
                        margin: 0 auto;

                        .primary {
                            float: right;
                        }
                    }
                }

                .action.primary {
                    @include cblys-re-outline-brown-btn;
                    border-radius: $border-radius__l;
                    margin: 0 auto;
                }
            }

            .log-in-reminder {
                padding: 0 0 0 10px;
            }

            klarna-placement *::part(osm-container), klarna-placement::part(osm-container) {
                margin: 0 10px 10px 10px;
            }
        }
    }

    .cart-totals {
        .table-wrapper {
            .table.totals {
                font-size: unset;
                letter-spacing: normal;
                line-height: inherit;

                .totals-separator {
                    display: none;
                }

                tr.totals,
                tr.totals-tax,
                tr.shipping_from,
                tr.total-rules {
                    th,
                    td {
                        padding: 3px 10px;
                    }

                    .price {
                        font-size: 1.15rem;
                        line-height: 1.4375rem;
                    }
                }

                tr.totals.discount-summary {
                    display: table-row;
                }

                tr.total-rules {
                    color: inherit;

                    th.mark,
                    td.amount {
                        padding: 11px 10px;
                    }

                    td.amount {
                        font-weight: normal;
                    }

                    .rule-amount {
                        float: none;
                    }
                }

                tr.grand.totals {
                    strong,
                    span {
                        font-weight: normal;
                    }
                }

                tr.totals-tax {
                    font-size: 1rem;
                    line-height: 1.42857;
                    letter-spacing: normal;

                    th.mark {
                        padding-left: 20px;
                    }

                    .price {
                        font-size: 1rem;
                        line-height: 1.25rem;
                    }
                }

                .shipping_from {
                    td {
                        color: $cblys__darkbrown;
                        text-align: right;
                        padding: 0.25rem 0.8rem;
                        letter-spacing: 0.04em;
                    }
                }
            }
        }
    }

    .cart-empty {
        display: block;
        margin: 0;
    }
}

@include max-screen($screen__l) {
    .checkout-cart-index-version-1 {
        .cart-container {
            .cart-summary {
                width: auto;
            }
        }
    }
}

@include max-screen($screen__m) {
    .checkout-cart-index-version-1 {
        .cart {
            &.table-wrapper {
                .cart.item {
                    .item-info {
                        align-items: stretch;
                        grid-template-areas:
                            "image details details"
                            "image quantity subtotal";
                    }

                    .col.item {
                        margin-right: 0;
                    }

                    .col.qty {
                        padding: 0;

                        .field.qty {
                            margin-top: 12px;
                        }

                        .energy-label {
                            justify-content: flex-end;
                            margin: 12px 0 0 0;

                            a {
                                order: 0;
                            }
                        }
                    }

                    .col.subtotal {
                        align-self: end;
                        padding-bottom: 4px;
                    }
                }
            }
        }

        .cart-container {
            .cart-summary {
                box-shadow: none;
                float: none;
                order: 1;
            }
        }
    }
}

@include max-screen($screen__xxs) {
    .checkout-cart-index-version-1 {
        .cart.table-wrapper {
            .cart.item {
                .item-info {
                    grid-template-areas:
                    "image details details"
                    "image quantity quantity"
                    "image subtotal subtotal";
                }

                .col.subtotal {
                    flex-direction: row;
                    justify-content: start;
                    margin-top: 12px;

                    .old-price {
                        margin-right: 12px;
                    }
                }
            }
        }
    }
}
