@include lib-font-face(
    $family-name: "Cblys Icons",
    $font-path: "../fonts/icomoon",
    $font-weight: 400,
    $font-style: normal
);

[class^="icon-"],
[class*=" icon-"] {
    @include lib-icon-font(
        $_icon-font-content: "",
        $_icon-font-size: 13px,
        $_icon-font-line-height: 26px,
        $_icon-font-text-hide: true,
        $_icon-font-display: block
    );

    &::before {
        margin-right: 0.5rem;
    }
}

.icon-kjkken:before {
    content: "\e930";
}

.icon-toalett:before {
    content: "\e931";
}

.icon-home:before {
    content: "\e92f";
}

.icon-question:before {
    content: "\e92e";
}

.icon-cblogo:before {
    content: "\e92d";
}

.icon-avfall:before {
    content: "\e925";
}

.icon-freight:before {
    content: "\e932";
}

.icon-map-wp:before {
    content: "\e926";
}

.icon-payment:before {
    content: "\e927";
    font-size: 1.2rem;
}

.icon-privacy:before {
    content: "\e928";
}

.icon-betingelser:before {
    content: "\e92c";
}

.icon-kontakt:before {
    content: "\e929";
}

.icon-bytte:before {
    content: "\e92a";
}

.icon-sub:before {
    content: "\e920";
}

.icon-retur-wp:before {
    content: "\e92b";
}

.icon-cac:before {
    content: "\e924";
}

.icon-add:before {
    content: "\e900";
}

.icon-skinner:before {
    content: "\e91b";
}

.icon-addtocart:before {
    content: "\e901";
}

.icon-arrow_down:before {
    content: "\e902";
}

.icon-arrow_right:before {
    content: "\e903";
}

.icon-arrow_up:before {
    content: "\e904";
}

.icon-bordlampe:before {
    content: "\e905";
}

.icon-cacold:before {
    content: "\e906";
}

.icon-cart:before {
    content: "\e907";
}

.icon-check:before {
    content: "\e908";
}

.icon-close:before {
    content: "\e909";
}

.icon-facebook:before {
    content: "\e90a";
}

.icon-gulvlampe:before {
    content: "\e90b";
}

.icon-instagram:before {
    content: "\e90c";
}

.icon-lightbulb_on:before {
    content: "\e90d";
}

.icon-lysekrone:before {
    content: "\e90e";
}

.icon-mail:before {
    content: "\e90f";
}

.icon-map:before {
    content: "\e910";
}

.icon-menu:before {
    content: "\e911";
}

.icon-messenger:before {
    content: "\e912";
}

.icon-misc:before {
    content: "\e913";
}

.icon-pendel:before {
    content: "\e914";
}

.icon-phone:before {
    content: "\e915";
}

.icon-pil:before {
    content: "\e916";
}

.icon-plafond:before {
    content: "\e917";
}

.icon-retur:before {
    content: "\e918";
}

.icon-search:before {
    content: "\e919";
}

.icon-shipping:before {
    content: "\e91a";
}

.icon-skjerm:before {
    content: "\e91c";
}

.icon-spotter:before {
    content: "\e91d";
}

.icon-star_empty:before {
    content: "\e91e";
}

.icon-star_filled:before {
    content: "\e91f";
}

.icon-user:before {
    content: "\e921";
}

.icon-ute:before {
    content: "\e922";
}

.icon-vegglampe:before {
    content: "\e923";
}

.icon-delivery-info:before {
    content: "\e92e";
    font-size: 16px;
    line-height: 1.1;
    display: flex;
    margin-left: 0.25rem;
}

/* Not in font pack, no docs on how to regenerate pack */
.icon-heart:before {
    content: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 24 24" fill="none"><path d="M19.0554 3.41708C19.7228 3.69365 20.3292 4.099 20.84 4.61C21.351 5.12076 21.7563 5.72719 22.0329 6.39464C22.3095 7.0621 22.4518 7.77751 22.4518 8.5C22.4518 9.22249 22.3095 9.9379 22.0329 10.6054C21.7563 11.2728 21.351 11.8792 20.84 12.39L19.78 13.45L12 21.23L4.22 13.45L3.16 12.39C2.1283 11.3583 1.54871 9.95903 1.54871 8.5C1.54871 7.04097 2.1283 5.64169 3.16 4.61C4.19169 3.57831 5.59096 2.99871 7.05 2.99871C8.50903 2.99871 9.9083 3.57831 10.94 4.61L12 5.67L13.06 4.61C13.5708 4.099 14.1772 3.69365 14.8446 3.41708C15.5121 3.14052 16.2275 2.99817 16.95 2.99817C17.6725 2.99817 18.3879 3.14052 19.0554 3.41708Z" stroke="#{$cblys__pear}" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
    transform: translateY(2px);
}

.icon-heart:hover:before {
    content: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 24 24" fill="none"><path d="M19.0554 3.41708C19.7228 3.69365 20.3292 4.099 20.84 4.61C21.351 5.12076 21.7563 5.72719 22.0329 6.39464C22.3095 7.0621 22.4518 7.77751 22.4518 8.5C22.4518 9.22249 22.3095 9.9379 22.0329 10.6054C21.7563 11.2728 21.351 11.8792 20.84 12.39L19.78 13.45L12 21.23L4.22 13.45L3.16 12.39C2.1283 11.3583 1.54871 9.95903 1.54871 8.5C1.54871 7.04097 2.1283 5.64169 3.16 4.61C4.19169 3.57831 5.59096 2.99871 7.05 2.99871C8.50903 2.99871 9.9083 3.57831 10.94 4.61L12 5.67L13.06 4.61C13.5708 4.099 14.1772 3.69365 14.8446 3.41708C15.5121 3.14052 16.2275 2.99817 16.95 2.99817C17.6725 2.99817 18.3879 3.14052 19.0554 3.41708Z" stroke="#{$cblys__pear-shade}" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
}

.icon-trash:before {
    content: svg-uri('<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M2.5 5H4.16667H17.5" stroke="#5C554E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M6.66667 5.00002V3.33335C6.66667 2.89133 6.84227 2.4674 7.15483 2.15484C7.46739 1.84228 7.89131 1.66669 8.33334 1.66669H11.6667C12.1087 1.66669 12.5326 1.84228 12.8452 2.15484C13.1577 2.4674 13.3333 2.89133 13.3333 3.33335V5.00002M15.8333 5.00002V16.6667C15.8333 17.1087 15.6577 17.5326 15.3452 17.8452C15.0326 18.1578 14.6087 18.3334 14.1667 18.3334H5.83334C5.39131 18.3334 4.96739 18.1578 4.65483 17.8452C4.34227 17.5326 4.16667 17.1087 4.16667 16.6667V5.00002H15.8333Z" stroke="#5C554E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M8.33333 9.16669V14.1667" stroke="#5C554E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M11.6667 9.16669V14.1667" stroke="#5C554E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>');
}
