@use 'sass:math';

//
//  Variables
//  _____________________________________________

$account-title-border-color: $color-gray-middle2;
$account-table-border-bottom-color: $color-gray-middle1;
$account-table-action-delete: $color-red12;

$_password-default: $cblys__beige;
$_password-weak: #ffafae;
$_password-medium: #ffd6b3;
$_password-strong: #c5eeac;
$_password-very-strong: #81b562;


//
//  Common
//  _____________________________________________

.block-collapsible-nav {
    &-title {
        @include cblys-base-button($_cblys-button__states: false);
        justify-content: left;

        &::after {
            @include material-icon('keyboard_arrow_down');
            margin-left: auto;
        }
    }
    &-content {
        display: none;

        .items {
            padding: 0;
            list-style: none;
            font-size: 1.2rem;
        }

        .item {
            padding-left: 10px;

            &.current {
                border-left: 2px solid $cblys__black;
            }
        }

        &.active {
            display: block;
        }
    }
}

.actions-toolbar {
    padding: 1rem;
    margin: 0 2px;

    .back, .remind {
        text-decoration: underline;
    }
}

.customer-account-login {
    .column.main {
        margin-top: 13px;
    }
}

.login-container {
    .block {
        &-customer-login {
            @include cblys-panel($cblys__white);

            .actions-toolbar {
                padding: 0;
                margin: 12px 0;

                .action.login.primary {
                    padding: 16px 24px;
                }
            }
        }

        &-new-customer {
            @include cblys-panel($cblys__re-beige);

            .actions-toolbar {
                padding: unset;
                margin: unset;
            }

            .csp {
                margin: 0;
                padding: 0;
                list-style: none;

                li {
                    display: flex;
                    align-items: center;
                    column-gap: 8px;

                    img {
                        display: block;
                    }
                }
            }

            .fieldset {
                margin: 0 0 12px 0;
                padding: 0;
            }
        }

        .block-title {
            font-weight: bold;
            font-size: 24px;
            line-height: 30px;
            margin-bottom: 20px;
            font-family: $font-family__header;
        }

        .block-content {
            font-size: 16px;
        }
    }

    .action.primary {
        background-color: $cblys__re-light-green;
        border: none;
        padding: 14px;
        letter-spacing: 0.56px;
    }

    .fieldset {
        &:after {
            margin-top: 35px;
        }

        &.login {
            padding: unset;
            margin: unset;
        }
    }
}

.block-addresses-list {
    .items.addresses {
        > .item {
            margin-bottom: $indent__base;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .item.actions {
            margin-top: $indent__xs;

            .action {
                &:after {
                    border-left: 1px solid $primary__color__light;
                    content: '';
                    display: inline-block;
                    height: 12px;
                    margin: 0 $indent__s;
                    vertical-align: -1px;
                }

                &:last-child {
                    &:after {
                        display: none;
                    }
                }
            }
        }

    }

    address {
        line-height: 24px;
    }
}

.form-address-edit {
    #region_id {
        display: none;
    }

    .actions-toolbar .action.primary {
        @extend .abs-button-l;
    }
}

.form-edit-account {
    .fieldset.password {
        display: none;
    }
}

.form-create-account {
    .fieldset-fullname {
        .fields {
            .field {
                float: none;
            }
        }
    }
}

.box-billing-address,
.box-shipping-address,
.box-information,
.box-newsletter {
    .box-content {
        line-height: 24px;
    }
}

//  My account
.account {
    .page-title-wrapper {
        .page-title {
            display: inline-block;
        }
    }

    .column.main {
        margin-bottom: $indent__l;

        h2 {
            margin-top: 0;
        }

        .block:not(.widget) {
            @extend .abs-account-blocks;
        }

        a {
            text-decoration: underline;
        }
    }

    .sidebar-additional {
        margin-top: $indent__xl;
    }

    .table-wrapper {
        margin-bottom: $indent__base;
        border-bottom: 1px solid $account-table-border-bottom-color;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .legend {
        > strong,
        > span {
            font-size: 22px;
            font-weight: $font-weight__light;
        }

        border-bottom: 1px solid $account-title-border-color;
        margin-bottom: $indent__m;
        padding-bottom: $indent__s;
    }

    fieldset {
        .message {
            box-shadow: none;
            margin-top: 10px;
        }
    }
}

.account,
[class^='sales-guest-'],
.sales-guest-view {
    .column.main {
        .order-details-items {
            .table-wrapper {
                .data.table {
                    @include lib-table-striped(
                            $_stripped-highlight: even
                    );
                    @include lib-table-bordered(
                            $_table_type: light
                    );
                }
            }
        }
    }

    .data.table {
        .col.actions {
            .action {
                &:after {
                    border-left: 1px solid $primary__color__light;
                    content: '';
                    display: inline-block;
                    height: 12px;
                    margin: 0 $indent__s;
                    vertical-align: -1px;
                }

                &:last-child {
                    &:after {
                        display: none;
                    }
                }

                &.delete {
                    color: $account-table-action-delete;
                }
            }
        }
    }
}

//  Checkout address (create shipping address)
.field.street {
    .field.additional {
        .label {
            @extend .abs-visually-hidden;
        }
    }
}

//
//  Blocks & Widgets
//  ---------------------------------------------

.block {
    @extend .abs-margin-for-blocks-and-widgets;

    .column.main & {
        &:last-child {
            margin-bottom: 0;
        }
    }

    p {
        &:last-child {
            margin: 0;
        }
    }

    .box-actions {
        margin-top: $indent__xs;

        .action {
            &:after {
                border-left: 1px solid $primary__color__light;
                content: '';
                display: inline-block;
                height: 12px;
                margin: 0 $indent__s;
                vertical-align: -1px;
            }

            &:last-child {
                &:after {
                    display: none;
                }
            }
        }
    }
}

//
//  Password Strength Meter
//  ---------------------------------------------

.field.password {
    .control {
        display: flex;
        flex-direction: column;

        .mage-error {
            order: 2;
        }

        .input-text {
            order: 0;
        }
    }
}

.form-edit-account .password-strength-meter {
    background-color: $_password-default;
    height: $form-element-input__height;
    line-height: $form-element-input__height;
    padding: $form-element-input__padding;
    position: relative;
    z-index: 1;

    &:before {
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        z-index: -1;
    }

    .password-strength-meter-0 & {
        &:before {
            background-color: $_password-default;
            width: 100%;
        }
    }

    .password-strength-meter-1 & {
        &:before {
            background-color: $_password-weak;
            width: 25%;
        }
    }

    .password-strength-meter-2 & {
        &:before {
            background-color: $_password-medium;
            width: 50%;
        }
    }

    .password-strength-meter-3 & {

        &:before {
            background-color: $_password-strong;
            width: 75%;
        }
    }

    .password-strength-meter-4 & {
        &:before {
            background-color: $_password-very-strong;
            width: 100%;
        }
    }
}

.customer-account-create {
    .password-strength-meter {
        background-color: $_password-default;
        border-radius: $border-radius__xs;
        padding: 8px 16px;
        margin-right: 8px;
    }

    .password-strength-meter--title {
        font-size: 16px;
    }

    .password-strength-meter--progressbar {
        height: 16px;
        max-width: 100%;
        width: 245px;
        background-color: $cblys__almostdarkgray;
        position: relative;
        border-radius: $border-radius__xs;

        &:after {
            content: '';
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            border-radius: $border-radius__xs;
        }
    }

    .password-none .password-strength-meter--progressbar:after {
        background-color: $cblys__almostdarkgray;
        width: 100%;
    }

    .password-weak .password-strength-meter--progressbar:after {
        background-color: $_password-weak;
        width: 25%;
    }

    .password-medium .password-strength-meter--progressbar:after {
        background-color: $_password-medium;
        width: 50%;
    }

    .password-strong .password-strength-meter--progressbar:after {
        background-color: $_password-strong;
        width: 75%;
    }

    .password-very-strong .password-strength-meter--progressbar:after {
        background-color: $_password-very-strong;
        width: 100%;
    }

    .password-strength-meter--label {
        margin-top: 4px;
    }

    .password-validation {
        display: flex;
        align-items: center;

        .yes {
            display: none;
        }

        &.valid {
            .yes {
                display: block;
            }

            .no {
                display: none;
            }
        }
    }
}

.control.captcha-image {
    margin-top: $indent__s;

    .captcha-img {
        vertical-align: middle;
    }
}

.account {
    .data.table-return-items {
        .qty {
            .input-text {
                @extend .abs-input-qty;
            }
        }
    }
}

.order-products-toolbar {
    position: relative;
}

.form.password,
.form.create,
.block-order-details-view .block-content,
.table-wrapper.order-items,
.table-wrapper.orders-history {
    border-radius: $cblys__border-radius;
    background: $cblys__white;
}

.block-order-details-view .block-content {
    padding: 10px;
}

.register-new-user {
    padding: 0 1rem 1rem;
    font-size: 18px;

    p {
        margin-bottom: 1.5rem;
    }

    ul {
        padding-left: 1rem;
        font-size: 1.2rem;
        font-weight: bold;
    }

    .additional-text {
        font-style: italic;
        font-weight: normal;
    }
}

.account .actions-toolbar {
    margin-bottom: 0;
}

.customer-account-create {
    .form-create-account {
        border-radius: $border-radius__xs;
        padding: 28px;
    }

    .field.date .customer-dob input {
        width: 100%;
    }

    .customer-dob .ui-datepicker-trigger {
        display: none;
    }

    .field .control select {
        margin: 0.5rem 0.5rem 0.5rem 0;
        height: 52px;
        background: inherit;
    }


    .fieldset > .field {
        > .field.choice {
            margin-top: 20px;
        }

        > .field.choice ~ .field.choice {
            margin-top: 0;
        }
    }

    .action.primary {
        background-color: $cblys__re-light-green;
        border: none;
        padding: 14px;
        letter-spacing: 0.56px;
    }
}

.form-edit-account {
    .field.required > label > span:after {
        content: '*';
    }
}

.form-create-account {
    .field.required > label > span:after {
        display: none;
    }

    .field.optional > label > span:after {
        content: ' (' attr(data-optional-text) ')';
        color: $cblys__re-lighter-brown;
    }

    .validation-icons {
        display: none;
    }

    .validation-icon {
        width: 35px;
        height: 35px;
        display: block;
    }

    .control {
        display: flex;
        align-items: center;
        position: relative;

        .mage-error {
            position: absolute;
            top: 0;
            right: -35px;
            bottom: 0;
            width: 35px;
            display: flex;
            align-items: center;
        }

        input.checkbox {
            margin-top: 0;
        }
    }

    .agreeterms label {
        a {
            text-decoration: underline;
        }

        img {
            margin-bottom: -2px;
        }
    }

    .show-password {
        position: absolute;
        top: 15px;
        right: 20px;
        width: 35px;
        height: 35px;

        img.no {
            display: none;
        }

        img.yes {
            display: block;
        }

        label {
            cursor: pointer;
        }

        input {
            @extend .abs-visually-hidden;

            &:checked + label {
                img.no {
                    display: block;
                }

                img.yes {
                    display: none;
                }
            }
        }
    }
}

.customer-account-forgotpassword {
    .form.password.forget {
        border-radius: $border-radius__xs;
    }
}

.customer-account-login, .customer-account-create, .customer-account-forgotpassword {
    @mixin customer-primary-action-spacings {
        border-radius: $border-radius__l;
        padding: 16px 24px;
        line-height: math.div(20rem, 14);
        letter-spacing: math.div(0.4rem, 14);
    }

    #maincontent {
        input, select {
            border-radius: $border-radius__xs;
        }
    }

    a.action {
        font-size: 1rem;
        font-family: $font-family__base;
        transition: 0.2s;
    }

    a.action.create.primary {
        background: $cblys__white;
        @include customer-primary-action-spacings;

        &:hover,
        &:active {
            background-color: $cblys__re-light-green;
            box-shadow: unset;
        }
    }

    button.action.primary {
        @include cblys-re-green-btn;
        @include customer-primary-action-spacings;
        width: 100%;
    }

    .secondary {
        display: flex;
    }

    .secondary a.action {
        border-radius: $border-radius__l;
        padding: 14px;
        margin: 0;
        letter-spacing: math.div(0.56rem, 14);
        text-decoration: none;

        &:hover,
        &:active {
            background-color: $cblys__re-light-green;
        }
    }
}

.grecaptcha-badge {
    border-radius: $border-radius__xs;

    iframe {
        border-radius: $border-radius__xs;
    }
}

.captcha .captcha-reload {
    border-radius: $border-radius__l;
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {

    .login-container {
        @extend .abs-add-clearfix-desktop;

        .block {
            @extend .abs-blocks-2columns;

            &.login {
                .actions-toolbar {
                    > .primary {
                        margin-bottom: 0;
                        margin-right: $indent__l;
                    }

                    > .secondary {
                        float: left;
                    }
                }
            }
        }

        .fieldset {
            > .field {
                > .control {
                    width: 100%;
                }
            }
        }
    }

    .register-new-user {
        display: block;
        max-width: 600px;
        padding: 1rem;
    }

    .form-create-account {
        .fieldset-fullname {
            .fields {
                .field {
                    float: left;
                    margin: 0 $indent__s $indent__s 0;
                }
            }

            .field-name-prefix,
            .field-name-suffix {
                width: 50px;
            }
        }
    }

    .form.password.reset,
    .form.send.confirmation,
    .form.password.forget,
    .form.create.account {
        min-width: 600px;
        width: 50%;
    }

    //  My account
    .account.page-layout-2columns-left {
        .sidebar-main,
        .sidebar-additional {
            width: 22.3%;
        }

        .column.main {
            width: 77.7%;
        }
    }

    .account {
        .block-collapsible-nav {
            &-title {
                display: none;
            }

            &-content {
                display: block;
            }
        }
        .page-title-wrapper {
            @extend .abs-add-clearfix-desktop;
        }

        .column.main {
            .block:not(.widget) {
                .block-content {
                    @extend .abs-add-clearfix-desktop;

                    .box {
                        @extend .abs-blocks-2columns;
                    }
                }
            }
        }

        .data.table {
            margin-bottom: 0;

            .col {
                &.actions {
                    white-space: nowrap;
                }
                &.total {
                    display: inline-block;
                }
            }
        }

        .toolbar {
            @extend .abs-pager-toolbar;
        }
    }

    .block-addresses-list {
        .items.addresses {
            @extend .abs-add-clearfix-desktop;

            > .item {
                @extend .abs-blocks-2columns;
                margin-bottom: $indent__base;

                &:nth-last-child(1),
                &:nth-last-child(2) {
                    margin-bottom: 0;
                }
            }
        }

        address {
            font-size: 16px;
        }
    }

    .page-main {
        .block {
            margin-bottom: $indent__xl + $indent__s;
        }
    }

    .form-address-edit {
        .fieldset {
            @extend .abs-blocks-2columns;
        }
    }

    .form-edit-account {
        .fieldset {
            @extend .abs-blocks-2columns;

            .fieldset {
                margin-bottom: $indent__base;
                width: 100%;
            }
        }
    }

    .box-billing-address,
    .box-shipping-address,
    .box-information {
        .box-content {
            font-size: 16px;
        }
    }

    .control.captcha-image {
        .captcha-img {
            margin: 0 $indent__s $indent__s 0;
        }
    }
}

//
//  Mobile $screen__s
//  _____________________________________________

@include max-screen($screen__s) {
    .account {
        .column.main,
        .sidebar-additional {
            margin: 0;
        }

        .data.table {
            > tbody > tr > td:last-child {
                border: 0;
            }
        }
    }
}

//
//  Mobile $screen__m
//  _____________________________________________

@include max-screen($screen__m) {

    .account {
        .sidebar-main {
            order: -1;
        }
    }

    .login-container {
        .fieldset {
            &:after {
                text-align: center;
            }
        }
    }

    .account {

        .column.main {
            .block:not(.widget) {
                .block-title {
                    @extend .abs-add-clearfix-mobile-m;

                    > .action {
                        float: right;
                        margin-top: $indent__xs;
                    }
                }

                .block-content {
                    .box {
                        margin-bottom: $indent__base;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }

    .control.captcha-image {
        .captcha-img {
            display: block;
            margin-bottom: $indent__s;
        }
    }

    .customer-account-index {
        .page-title-wrapper {
            position: relative;
        }
    }

    .customer-account-create {
        .columns {
            width: 100%;
        }

        .page-main {
            .page-title-wrapper {
                justify-content: inherit;
            }
        }
    }
}

// Styles moved from CMS block
body.account {
    .block-static-block.widget, .block-cms-link.widget {
        margin: 0
    }

    .column.main {
        padding: 24px;

        input {
            border: 1px solid $cblys__darkbrown;
        }
    }

    @include max-screen($screen__m) {
        main#maincontent {
            padding-top: 0
        }

        .block.account-nav {
            margin: 0;
        }
    }

    .page-title-wrapper {
        justify-content: center;
        margin-bottom: 24px;
    }

    legend.legend {
        border-color: $cblys__beige !important;
    }

    .block-title {
        border-color: $cblys__beige !important;
    }

    .title.block-collapsible-nav-title {
        background: $cblys__beige;
        border: unset;
        padding: 16px 24px;
        font-family: $font-family__base;
    }

    .block.block-collapsible-nav {
        margin-bottom: 0;
    }

    li.nav.item.current {
        padding: 12px 24px;
    }

    li.nav.item:not(.current) {
        padding-left: 24px;
        margin: 24px 0;
    }

    @include min-screen($screen__m) {
        input {
            transition: 0.2s;
        }

        input:hover {
            background: $cblys__beige;
            border: 1px solid $cblys__beige;
        }
    }

    select#country {
        background: white;
        border: 1px solid $cblys__black;
        height: 52px;
        line-height: 1.42857;
        margin: 0.5rem 0;
        padding: 0.25rem 0.75rem;
        transition: 0.2s;
    }

    select#country:hover {
        background: $cblys__beige;
        border: 1px solid $cblys__beige;
    }

    button.action.primary {
        background: $cblys__beige;
        border: unset;
        font-size: 14px !important;
        font-family: $font-family__base;
        line-height: 20px !important;
        letter-spacing: 0.4px;
        padding: 16px 24px;
    }

    button.action.primary:hover {
        box-shadow: unset;
        background: $cblys__beige-main;
    }
}
