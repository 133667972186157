@use 'sass:math';

//
//  Variables
//  _____________________________________________

$minicart__border-color: $color-gray80 !default;
$minicart__padding-horizontal: $indent__base !default;

$minicart-qty__height: 24px !default;


//
//  Minicart
//  ---------------------------------------------

.minicart-wrapper .block-minicart {
    .block-header {
        align-items: center;
        background-color: $cblys__beige;
        display: flex;
        padding: 40px 24px 24px 24px;

        .block-title {
            margin: 0;
            line-height: 1.3;
            font-size: math.div(30rem, 14);
        }
    }

    .action.primary, .action.viewcart button {
        border-radius: $border-radius__l;
    }

    .items-total {
        display: none;
        float: left;
        margin: 0 $indent__s;
        margin-top: 0.25rem;

        .count {
            font-weight: $font-weight__bold;
        }
    }

    .amount.price-container {
        tr.totals,
        tr.totals-tax {
            th.mark,
            td.amount {
                padding: 3px 0;
                font-weight: 400;
            }
        }

        .shipping_from {
            td {
                color: $cblys__darkbrown;
                text-align: right;
                padding: 0.25rem 0;
                letter-spacing: 0.04em;
            }
        }
    }

    div.subtotal {
        margin: 12px 0;
        align-items: center;

        .price-container {
            tr.totals, tr.shipping_from {
                th, td {
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }
    }

    .currency-symbol {
        text-transform: lowercase;
    }

    .subtitle {
        display: none;

        &.empty {
            display: block;
            font-size: 14px;
            padding: 16px 0 8px;
        }
    }

    .content.empty {
        > div {
            margin-top: 32px;
        }

        h6 {
            font-family: $font-family__base;
            font-size: math.div(18rem, 14);
            margin: 0;
            padding: 8px 0;
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                font-size: math.div(16rem, 14);
                padding: 8px 0;
            }
        }
    }

    .text {
        &.empty {
            text-align: center;
        }
    }

    .log-in-reminder {
        margin: 12px 0;
    }

    li.item:hover {
        background: none;
        cursor: auto;
    }

    .block-content {
        display: flex;
        flex-direction: column;
        height: calc(100% - 103px);
        overflow: auto;
        padding: 24px 24px 40px 24px;

        > * {
            flex-shrink: 0;
        }

        .actions-toolbar {
            margin: 12px 0 0 0;
            padding: 0;
            text-align: right;

            button {
                padding: 14.5px;
            }

            .primary {
                display: flex;
                float: none;
                margin: 0;
                width: 100%;

                > * {
                    width: 50%;

                    &:first-child {
                        margin-right: 5px;
                    }

                    &:last-child {
                        margin-left: 5px;
                    }
                }
            }

            .action.viewcart button {
                letter-spacing: 1px;
                width: 100%;
            }

            #top-cart-btn-checkout {
                border: 0;
                background-color: $cblys__pear;
                letter-spacing: 1px;
            }
        }
    }

    .crosssell {
        .product-items-crosssell {
            .product-item {
                grid-template-areas:
                    'image content'
                    'actions actions';

                &-actions {
                    flex-direction: row;
                }

                &-labels {
                    flex-direction: row-reverse;
                }

                .energy-label {
                    a {
                        order: 0;
                    }
                }
            }
        }

        .product-item {
            margin: 16px 0;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .block.discount {
        margin: 12px 0 2px 0;

        #block-discount-heading {
            margin: 0 0 10px 0;
            padding-left: 0;
        }
    }

    .action.close,
    .action.delete,
    .action.edit,
    .action.remove {
        transform: none;
    }

    .block-category-link,
    .block-product-link,
    .block-cms-link,
    .block-banners {
        margin: 15px 0 0;
        text-align: center;
    }

    .out-of-stock {
        color: $cblys__red;
        display: flex;
        align-items: center;
        font-style: normal;
        font-size: 10px;
        line-height: 16px;
        letter-spacing: 0.5px;
        text-transform: uppercase;

        &:before {
            display: block;
            content: "";
            width: 6px;
            height: 6px;
            border-radius: 50%;
            margin-right: 5px;
            background: $cblys__red;
        }
    }
}

.minicart-wrapper {
    @include lib-dropdown(
        $_toggle-selector: ".action.showcart",
        $_options-selector: ".block-minicart",
        $_dropdown-toggle-icon-content: $icon-cart,
        $_dropdown-toggle-active-icon-content: $icon-cart,
        $_dropdown-list-border: 0,
        $_dropdown-list-item-padding: false,
        $_dropdown-list-item-hover: false,
        $_dropdown-list-margin-top: 0,
        $_icon-font-position: before,
        $_icon-font-size: 26px,
        $_icon-font-line-height: 26px,
        $_icon-font-color: $cblys__pine,
        $_icon-font-color-hover: $minicart-icons-color-hover,
        $_icon-font-color-active: $minicart-icons-color,
        $_dropdown-list-shadow: none,
    );
    opacity: 0.5;
    pointer-events: none;
    order: 10;
    padding: 6px 12px;

    &.loaded {
        opacity: 1;
        pointer-events: auto;
    }

    &.active .block-minicart {
        right: 0;
    }

    .block-minicart {
        background: $cblys__lightgray;
        border-left: 1px solid $cblys__lightgray3;
        display: block;
        height: 100vh;
        margin: 0;
        min-width: 0;
        padding: 0;
        position: fixed;
        top: 0;
        transition: 0.3s;
        right: -100%;
        width: 80vw;

        &:after,
        &:before {
            content: none;
        }

        .block-title {
            display: block;
            text-align: left;
            font-size: 24px;
        }

        &:after {
            left: auto;
            right: 25px;
        }

        &:before {
            left: auto;
            right: 26px;
        }
    }

    .product {
        .actions {
            float: right;
            margin: -24px 0 0;
            text-align: right;

            > .primary,
            > .secondary {
                display: inline;
            }
        }
    }

    .action {
        &.close {
            @include lib-icon-font($icon-remove, $_icon-font-size: 16px);
            position: relative;
            margin-left: auto;
            display: flex;
            flex-flow: row-reverse;
            width: auto;
            align-items: center;
            background: transparent;
            border: 0;
            max-width: fit-content;
            padding-right: 0;

            & > span {
                @include cblys-overline();
                clip: auto;
                height: auto;
                width: auto;
                position: relative;
                margin: 0 5px;
            }

            &:active,
            &:focus,
            &:hover {
                box-shadow: none;
            }

            &:before {
                color: $cblys__black;
                font-weight: bold;
            }
        }

        &.showcart {
            display: flex;
            flex-direction: column;
            position: relative;
            white-space: nowrap;
            background: none;
            border: 0;
            color: inherit;

            &.active {
                display: flex;
            }

            .subtotal,
            .counter.qty {
                display: none;
            }

            .text {
                font-size: 13px;
                line-height: 16px;
                letter-spacing: 0.5px;
                display: none;
            }

            .counter.qty {
                position: absolute;
                top: 3px;
                right: -10px;
                left: auto;
                display: flex;
                justify-content: center;
                align-items: center;
                background: $cblys__re-light-brown;
                color: $page__background-color;
                height: $minicart-qty__height;
                width: $minicart-qty__height;
                border-radius: 50%;
                margin: 3px 0 0;
                min-width: 18px;
                overflow: hidden;
                padding: 0 3px;
                text-align: center;
                white-space: normal;
                transform: scale(0.75);

                &.empty {
                    display: none;
                }

                .loader {
                    > img {
                        max-width: $minicart-qty__height;
                    }
                }
            }

            .counter-label {
                @extend .abs-visually-hidden;
            }
        }
    }

    .ui-widget-overlay {
        background: rgba($primary__color, 0.5);
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 7;
    }
}

.minicart-items {
    @include lib-list-reset-styles();

    &-wrapper {
        border: none;
        border-left: 0;
        border-right: 0;
        flex-grow: 1;
        overflow-x: auto;
        margin-bottom: 12px;
    }

    > .product-item {
        border-bottom: 1px solid $cblys__lightgray3;
        padding: 24px 0;

        &:first-child {
            padding-top: 0;
        }
    }

    .product-item-info:hover {
        background: none;
    }

    .product-item > .product {
        &:first-child {
            padding-top: 0;
        }

        &:not(:first-child) {
            border-top: none;
        }

        &:last-child {
            border-width: 1px;
        }

        .product-item-details {
            display: flex;
            flex-wrap: wrap;
            flex-grow: 1;
        }

        .energy-label-container {
            width: 100%;
        }

        .product-item-pricing {
            align-items: end;
            display: flex;
            margin-left: auto;
            order: 5;
            text-align: right;
        }

        .product-item-qty {
            display: flex;
            align-items: end;

            label {
                display: none;
            }

            .cart-item-qty {
                color: $cblys__black;
                margin: 0 4px;
                padding: 0;
                width: 32px;
            }

            .more,
            .less {
                background: $cblys__lightgray2;
                color: $cblys__pine;
                display: flex;
                justify-content: center;
                padding: 0;
            }
        }

        .product-item-options {
            width: 100%;

            .label,
            .values {
                margin-bottom: 0;

                @include cblys-caption();
            }
        }

        .product-item-name {
            @include cblys-text(math.div(16rem, 14), math.div(16rem, 14));
            width: 100%;
            text-align: left;
            font-weight: normal;
            margin: 0;
        }
    }

    .product-item-pricing {
        .label {
            display: inline-block;
            opacity: 0;
            width: 4.5rem;
        }
    }

    .price-minicart {
        margin-bottom: $indent__xs;
    }

    .product:not(.product-item) {
        display: flex;
        width: 100%;



        .product-item-photo {
            flex-shrink: 0;

            .product-image-wrapper {
                @extend .abs-reset-image-wrapper;
            }

            span.product-image-wrapper, img {
                border-radius: $border-radius__xs;
            }
        }

        .toggle {
            @include lib-icon-font(
                $_icon-font-content: $icon-down,
                $_icon-font-size: 28px,
                $_icon-font-line-height: 16px,
                $_icon-font-text-hide: false,
                $_icon-font-position: after,
                $_icon-font-display: block
            );
            cursor: pointer;
            position: relative;

            &:after {
                position: static;
            }
        }

        &.active {
            > .toggle {
                @include lib-icon-font-symbol(
                    $_icon-font-content: $icon-up,
                    $_icon-font-position: after
                );
            }
        }
    }

    .product-item-name {
        font-weight: $font-weight__regular;
        margin: 0 0 $indent__s;

        a {
            color: $link__color;
            display: block;
            font-size: math.div(16rem, 14);
        }
    }

    .product-item-details {
        margin: 0 0 0 16px;

        .weee[data-label] {
            font-size: 11px;

            .label {
                @extend .abs-no-display;
            }
        }

        .details-qty {
            margin-top: $indent__s;
            position: relative;
        }
    }

    .product.options {
        flex-direction: column;
        align-items: flex-start;

        .tooltip.toggle {
            @include lib-icon-font(
                $icon-down,
                $_icon-font-size: 28px,
                $_icon-font-line-height: 28px,
                $_icon-font-text-hide: true,
                $_icon-font-margin: -3px 0 0 7px,
                $_icon-font-position: after
            );

            .details {
                display: none;
            }
        }
    }

    .details-qty,
    .price-minicart {
        .label {
            @extend .abs-colon;
        }
    }

    .minicart-widgets {
        font-size: 11px;
        vertical-align: top;
        position: absolute;
        bottom: -14px;
        left: 155px;
    }

    .subtitle {
        display: none;
    }

    .action {
        &.edit,
        &.delete {
            @include lib-icon-font(
                $icon-settings,
                $_icon-font-size: 28px,
                $_icon-font-line-height: 28px,
                $_icon-font-text-hide: true,
                $_icon-font-color: $color-gray19,
                $_icon-font-color-hover: $color-gray19,
                $_icon-font-color-active: $color-gray19
            );
        }

        &.delete {
            position: absolute;
            right: 0;
            top: 0;

            @include lib-icon-font($icon-remove);
        }
    }
}

#minicart-content-wrapper {
    max-width: 400px;
    height: 100%;
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__xs) {
    .minicart-wrapper {
        .block-minicart {
            width: 290px;
        }

        &.active .block-minicart {
            padding: 1rem;
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .minicart-wrapper {
        .action.showcart {
            align-items: center;
            padding: 0.5px 0;

            .text {
                display: block;
                text-transform: capitalize;
            }
        }

        .block-minicart {
            width: 390px;
        }
    }
}

@include min-screen($screen__l) {
    .minicart-wrapper {
        .action.showcart {
            .text {
                display: block;
            }
        }
    }
}
