/**
* local defaults
*/
$meganav__text-color: $primary__color !default;
$meganav__text-color--inverse: $secondary__color;
$meganav__hover-border: $polarcore-primary-button__border !default;
$meganav__border: none !default;
$meganav__border-width: 2px !default;
$meganav__mobile-border: #f2f2f2 !default;
$color__white: $secondary__color !default;
$meganav__bold-font: 600 !default;
$meganav__regular-font: 400 !default;
$meganav__background-level0: #fff !default;
$meganav__background-level1: #fff !default;
$meganav__background-level2: #fff !default;
$meganav__background-custom-links: #fff !default;
$meganav-mobile__title-background: $primary__color !default;
$meganav-mobile__title-color: #fff !default;
$meganav__max-width: 900px !default;
$meganav__level0-height: 42px !default;
$meganav__font-size: 16px !default;
$megnanav__level0-font-size: 14px !default;
$meganav__level1-font-size: 24px !default;
$meganav__line-height: $meganav__font-size * 1.5;
$meganav__letter-spacing: 0.25px;
$meganav__icon-size--desktop: 16px !default;
$meganav__icon-size--mobile: 20px !default;

.page-header {
    border-bottom: none;
}

.meganav {
    font-family: $font-family__base, Helvetica, sans-serif;
}

.meganav__cms--header {
    padding-left: 1rem;
    font-size: 20px;
    font-weight: 500;
}

.meganav__opened {
    height: 100%;
    overflow: hidden;
}

body.sticky--down .page-header .meganav--desktop {
    .meganav__anchor.level0 {
        margin: 0;
        padding: 8px;
    }

    .meganav__anchor.level0:nth-child(4) {
        display: none;
    }
}

//
// Desktop only
//-----------------------------

@include min-screen($screen__l) {
    .hide_on_desktop {
        display: none !important;
    }

    body .meganav {
        margin: auto;
        width: 100%;
        max-width: $layout__max-width;
        padding: 0 1rem;

        &__list {
            &-root {
                max-width: $layout__max-width;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                margin: auto;
            }

            &--open {
                background: $meganav__background-level1;
                position: absolute;
                top: 100%;
                left: 0;
                right: 0;
                width: 100%;
                margin: auto;
                border: 0;
                padding: 0;
                columns: auto 1;
                column-gap: 0;
                flex: 1 0 auto;
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr;
                max-height: 75vh;
                max-width: $layout__max-width;
                overflow-y: auto;
                z-index: 10;
                animation-name: menuOpen;
                animation-duration: 200ms;
                opacity: 1;

                &::-webkit-scrollbar {
                    width: 4px;
                }

                &::-webkit-scrollbar-track {
                    background: #ffffff;
                }

                &::-webkit-scrollbar-thumb {
                    background: #515c45;
                }

                &::-webkit-scrollbar-thumb:hover {
                    background: #1c1c1b;
                }

                .meganav__item-cms--right {
                    display: none;
                }

                .meganav__list.level2 {
                    display: grid;
                    grid-template-columns: 1fr;
                    margin-top: 0.5rem;
                }

                & > .meganav--desktop-asset {
                    position: absolute;
                    bottom: 1rem;
                    right: 4rem;
                }
            }
        }

        &__item {
            padding: 0.6rem 0;
            text-decoration: none;
            text-transform: none;
            justify-content: space-between;
            font-size: $meganav__font-size;
            letter-spacing: $meganav__letter-spacing;

            &--open {
                .level0 {
                    background-color: $meganav__background-level0;
                }
            }

            &.level0 {
                padding: 0;
            }

            &.level1 {
                padding: 2rem;
                page-break-inside: avoid;
                break-inside: avoid-column;
                display: table;
                margin: 0;
            }

            &.level2 {
                font-size: $meganav__font-size;
                line-height: $meganav__line-height;
            }

            &--see-all {
                margin: 0 1rem 10px;

                a {
                    margin: 0;
                    @include lib-icon-font(
                        $_icon-font-content: $icon-arrow-left-thin,
                        $_icon-font-size: $meganav__icon-size--desktop,
                        $_icon-font-line-height: $meganav__icon-size--desktop
                    );
                    display: flex;

                    &::before {
                        margin-right: 10px;
                    }
                }
            }
        }

        &__anchor {
            display: inline-block;
            padding: 0;
            position: relative;

            .material-icons {
                width: 24px;
                position: absolute;
                left: -2rem;

                &[class*="icon-"] {
                    font-size: 13px;

                    &::before {
                        margin: 0;
                        font-size: $meganav__icon-size--desktop;
                    }
                }
            }

            &:hover {
                text-decoration: none;
            }

            &::after {
                content: "";
                position: absolute;
                width: 100%;
                transform: scaleX(0);
                height: 4px;
                left: 0;
                top: 90%;
                background-color: #e3d6c9; /* to-do change to variable after everything is merged */
                transform-origin: bottom right;
                transition: transform 0.25s ease-out;
            }

            &:hover::after {
                transform: scaleX(1);
                transform-origin: bottom left;
            }

            &.level0 {
                font-size: $megnanav__level0-font-size;
                font-weight: $meganav__regular-font;
                color: $meganav__text-color;
                margin: 0 0.5rem;
                line-height: 1rem;
                padding: 1rem;
            }

            &.level1 {
                font-size: $meganav__level1-font-size;
                font-weight: $meganav__bold-font;
            }

            &.level2 {
                letter-spacing: $meganav__letter-spacing;
            }
        }

        &__backdrop {
            background: rgba($primary__color, 0.5);
            top: $meganav__level0-height;
            position: absolute;
            left: calc((100% - 100vw) / 2);
            width: calc(100vw);
            height: 100vw;
        }

        &__backdrop--open {
            opacity: 1;
            animation-name: menuOpen;
            animation-duration: 500ms;
        }

        &__cms {
            &--list {
                list-style: none;
                padding: 0;
            }

            &--header {
                font-weight: 700;
            }
        }

        &-cms-block-container {
            display: none;
        }

        &__cms {
            &--list {
                list-style: none;
                padding: 0;
            }

            &--header {
                font-weight: 700;
            }
        }
    }
}

//
// Tablet
//----------------------------

@include screen($screen__m, $screen__l) {
    body .page-header .meganav--open {
        .meganav__list-root {
            width: 55%;
        }
    }
}

//
// Mobile only
//-----------------------------

@include max-screen($screen__l) {
    body .page-header .meganav {
        &__item {
            margin: 0 0 0 24px;

            &--back {
                @include cblys-close-button;
                position: absolute;
                right: 1rem;
                top: 0.9rem;

                &::before {
                    font-weight: bolder;
                }
            }

            &--title {
                padding: 24px;

                h5 {
                    text-align: left;
                    margin: 0;
                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: 0.08em;
                    text-transform: uppercase;
                    font-family: inherit;
                    font-weight: 700;
                    color: $cblys__meganav-open;
                }
            }

            &.level0 {
                display: table;
                &.has-children .meganav__anchor::after {
                    margin-right: 3.7rem;
                }
            }

            &.level0.meganav__item--open {
                background-color: $cblys__white;
                padding-bottom: 10px;

                .meganav__anchor.level0 {
                    color: $cblys__meganav-open;

                    &::after {
                        color: $cblys__beige;
                    }
                }

                .hide_on_tablet {
                    display: none;
                }
            }

            &.level0,
            &-cms--mobile {
                margin: 0;
            }

            &.level0 {
                .meganav__anchor {
                    display: flex;
                    align-items: center;
                    margin-left: 24px;
                    padding: 24px 0;
                    font-weight: bolder;
                    font-size: 24px;
                    line-height: 32px;
                }
            }

            &.level1 {
                margin-left: 0;

                .meganav__anchor {
                    margin-left: 24px;
                    width: 100%;

                    &.level1 {
                        font-weight: bold;
                        font-size: 18px;
                        padding: 10px 0;
                    }
                }

                &.has-children {
                    .meganav__anchor {
                        padding-left: 0;
                        width: inherit;
                        margin-left: 24px;

                        &.level1 {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-weight: bold;
                            padding: 12px 0;

                            &::after {
                                margin-right: 2rem;
                            }
                        }
                    }
                }
            }

            &.level1.meganav__item--open {
                .meganav__anchor.level1 {
                    color: $cblys__meganav-open;

                    &::after {
                        color: $cblys__beige;
                    }
                }
            }

            &.level2 {
                .meganav__anchor.level2 {
                    margin-left: 0;
                    width: 100%;
                    font-size: 16px;
                    padding-top: 0;
                    padding-bottom: 12px;
                    font-weight: normal;
                }
            }

            &--see-all {
                margin-left: 0;

                a {
                    margin-left: 10px;
                    font-weight: bold;
                    @include lib-icon-font(
                        $_icon-font-content: $icon-arrow-left-thin,
                        $_icon-font-size: $meganav__icon-size--mobile,
                        $_icon-font-line-height: $meganav__icon-size--mobile
                    );

                    &::before {
                        margin-right: 12px;
                        margin-left: 0;
                    }
                }
            }

            &.has-children > a {
                @include lib-icon-font(
                    $_icon-font-content: $icon-arrow-down,
                    $_icon-font-size: $meganav__icon-size--mobile,
                    $_icon-font-line-height: $meganav__icon-size--mobile,
                    $_icon-font-position: after
                );
                display: flex;

                &::after {
                    margin-left: auto;
                    transition: transform 0.3s;
                    font-size: 12px;
                }
            }

            &--open {
                &.has-children > a {
                    &::after {
                        transform: rotate(180deg);
                    }
                }
            }

            .meganav__list {
                display: none;
            }
        }

        &__anchor {
            padding: 10px 0;
            @include cblys-subtitle();
            font-weight: normal;
            margin-left: 2rem;
            position: relative;
            width: 100%;

            &.level0 {
                font-weight: bold;
                margin: 0;
            }

            &.level1 {
                font-weight: bold;
            }

            &.level2 {
                font-size: $meganav__font-size;
            }

            &:active,
            &:hover {
                text-decoration: none;
            }

            .material-icons {
                position: absolute;
                left: -30px;

                &[class*="icon-"] {
                    display: flex;

                    &::before {
                        font-size: $meganav__icon-size--mobile;
                    }
                }
            }
        }

        &__list {
            position: relative;

            &-root {
                overflow: scroll;
                box-shadow: none;
                -webkit-transform: translateX(-100%);
                transform: translateX(-100%);
                transition: transform 0.5s cubic-bezier(0.76, 0.11, 0.3, 1);
                position: fixed;
                background: $cblys__lightgray2;
                top: 0;
                bottom: 0;
                right: 50px;
                left: 0;
                z-index: 400;
            }

            &.level1 {
                display: none;
            }
        }

        &--open {
            .meganav__list {
                &-root {
                    -webkit-transform: translateX(0%);
                    transform: translateX(0%);
                    overflow-x: hidden;

                    &::-webkit-scrollbar {
                        display: none;
                    }
                }
            }
        }

        &--mobile-asset {
            background: none;
        }

        &-cms-block-container {
            &--buttons {
                display: flex;
                flex-direction: column;

                & > .meganav-cms-block-container--button {
                    @include cblys-menu-button;
                    padding: 0 10px;
                }
            }

            &--button {
                &::before {
                    padding-right: 0.5rem;
                }

                &.search {
                    @include lib-icon-font(
                        $_icon-font-content: $icon-search,
                        $_icon-font-size: 16px,
                        $_icon-font-line-height: 16px
                    );
                    display: flex;
                }

                &.stores {
                    @include lib-icon-font(
                        $_icon-font-content: $icon-location,
                        $_icon-font-size: 16px,
                        $_icon-font-line-height: 16px
                    );
                    display: flex;
                }

                &.mypage {
                    @include lib-icon-font(
                        $_icon-font-content: $icon-account,
                        $_icon-font-size: 16px,
                        $_icon-font-line-height: 16px
                    );
                    display: flex;
                }
            }
        }

        &__backdrop {
            background: $cblys__shadow;
        }

        &__backdrop--open {
            opacity: 1;
            animation-name: menuOpen;
            animation-duration: 200ms;
        }
    }
}

@keyframes menuOpen {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes backdropShadowClose {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
