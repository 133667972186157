//
//  Variables
//  _____________________________________________

$searchbar__height: 44px !default;

//
//  Common
//  _____________________________________________

.block-search {
    margin-bottom: 0;
    margin-right: auto;
    order: 0;
    width: 10%;
    max-width: none;
    display: flex;
    align-items: center;

    .block {
        &-title {
            @include lib-icon-font(
                $_icon-font-content: $icon-search,
                $_icon-font-size: 20px,
                $_icon-font-line-height: $searchbar__height,
                $_icon-font-color: $cblys__black,
                $_icon-font-color-hover: $minicart-icons-color-hover,
                $_icon-font-color-active: $cblys__black,
                $_icon-font-text-hide: true,
                $_icon-font-display: block
            );
            margin: 0;

            &::before {
                font-weight: bold;
            }

            strong {
                display: none;
            }
            &.active {
                display: none;
            }
        }
    }

    .block-content {
        display: none;
        background: $secondary__color;
        position: absolute;
        width: 100vw;
        top: 50px;
        left: 0;
        padding: $layout-indent__width;
        border-bottom: 1px solid $border-color__base;
        margin: 0;
        z-index: 5;
    }

    .field.search {
        display: flex;
    }

    .action.search {
        @include lib-icon-font(
            $_icon-font-content: $icon-search,
            $_icon-font-size: 27px,
            $_icon-font-line-height: $searchbar__height,
            $_icon-font-color: $minicart-icons-color,
            $_icon-font-color-hover: $cblys__pine-light,
            $_icon-font-color-active: $cblys__pine,
            $_icon-font-text-hide: true,
            $_icon-font-display: block
        );

        color: $cblys__pine;
        position: absolute;
        transform: scale(0.75);
        border: none;
        background: none;
        top: 3px;
        right: 1.3rem;
        padding: 0.5rem;
        opacity: 1;

        &.active {
            + .control {
                input {
                    position: static;
                }
            }
        }
        &:focus,
        &:active,
        &:hover {
            box-shadow: none;
            color: $cblys__pine-light;
        }
    }

    .label {
        display: none;
    }

    .control {
        display: flex;
        width: 100%;
        border: none;
    }

    input {
        background: $cblys__white;
        border-radius: $border-radius__l;
        height: $searchbar__height;
        max-height: $searchbar__height;
        font-size: 14px;
        border: none;
        margin: auto;
        color: $cblys__black;
        padding-left: 16px;

        &::placeholder {
            color: $cblys__black;
            letter-spacing: 0.04em;
        }

        &:focus {
            box-shadow: none;
        }
    }

    .nested {
        display: none;
    }
}

.search-autocomplete {
    display: none;
    top: 100%;
    overflow: hidden;
    overflow-y: scroll;

    .autocomplete-list {
        dl,
        dt,
        dd {
            margin: 0;
        }

        dd {
            padding: 0.5rem;
            &:hover {
                background-color: $cblys__gray;
            }
        }
        .title-product ~ dd {
            min-height: 83px;
        }
        .product-image-box {
            border-radius: $border-radius__xs;
            display: flex;
            justify-content: center;
            align-items: center;
            background: $cblys__gray;
            overflow: hidden;
            min-width: 45px;
            max-width: 45px;
        }
    }
}

.form.search.advanced {
    .fields.range {
        .field {
            &:first-child {
                position: relative;

                .control {
                    padding-right: 25px;

                    &:after {
                        content: " \2013 ";
                        display: inline-block;
                        position: absolute;
                        right: 0;
                        text-align: center;
                        top: 6px;
                        width: 25px;
                    }
                }
            }

            &:last-child {
                position: relative;

                div.mage-error[generated] {
                    left: 0;
                    position: absolute;
                    top: 32px;
                }
            }

            &.with-addon {
                .control {
                    padding-right: 45px;
                }
            }
        }
    }

    .group.price {
        .addon {
            .addafter {
                background: none;
                border: 0;
                padding-top: 6px;
                position: absolute;
                right: 0;
                top: 0;
            }
        }
    }
}

.search.summary {
    margin-bottom: $indent__s;
}

//
//  Tablet
//  _____________________________________________

@include min-screen($screen__m) {
    .header.content {
        .nav-toggle {
            display: flex;
            flex-direction: column;
            width: 64px;
            height: 60px;
            padding: 6px 12px;

            span {
                font-size: 13px;
                letter-spacing: 0.5px;
                line-height: 16px;
                clip: unset;
                overflow: unset;
                position: static;
                height: auto;
                width: auto;
                text-align: center;
                margin-top: 6px;
            }
        }
    }
    .block-search {
        text-align: center;

        .block {
            &-title {
                margin-top: -6px;

                strong {
                    display: block;
                    font-size: 13px;
                    line-height: 16px;
                    letter-spacing: 0.5px;
                    margin-top: -4px;
                    font-weight: normal;
                }
            }

            &-content {
                top: auto;
                left: 2rem;
                max-width: 280px;
                border: none;
                z-index: 6;
                background: none;
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__l) {
    body.sticky--clear .minisearch .input-text {
        transition: width 0.5s;
        width: 100%;

        &.hide {
            width: 12rem;
        }
    }

    .page-header .block.block-search {
        position: relative;
        max-width: 258px;
        width: 100%;
        padding: 0;

        .block-content {
            display: block;
            max-width: none;
            left: 0;
            width: 305px;
            position: relative;
            height: auto;
            top: unset;
            padding: 0;
        }

        .block-title {
            display: none;
        }

        .label {
            display: none;
        }

        .control {
            margin: 0;
            padding: 0 0 0;
            display: flex;
            justify-content: flex-end;
        }

        .nested {
            display: $_search-advanced__display;
            padding-top: 5px;
            position: absolute;
        }

        input {
            @include lib-input-placeholder() {
                color: $form-element-input-placeholder__color;
            }
            margin: 0;
            padding-right: 35px;
            position: static;
            max-height: 60px;
        }

        .action.search {
            top: -7px;
            right: 0;
        }
    }

    .search-autocomplete {
        margin-top: 0;
    }
}
