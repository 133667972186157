@use 'sass:math';

.product-stock.stock {
    display: flex;
    margin-bottom: 4px;
    @include cblys-body-text(math.div(13rem, 14));

    &::before {
        display: block;
        content: "";
        width: 6px;
        height: 6px;
        border-radius: 50%;
        margin: 6px 5px 6px 2px;
    }

    &.unavailable {
        &::before {
            display: none;
            background: $cblys__red;
        }
    }

    &.available {
        &::before {
            background: $cblys__green;
        }

        &__out-of-stock {
            &::before {
                background: $cblys__re-peach;
            }
        }

        &__backorder {
            &::before {
                background: $cblys__black;
            }
        }
    }

    .stock-count {
        margin-top: 2px;
    }
}
