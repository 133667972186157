@use 'sass:math';

.energy-label {
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-top: 16px;

    picture {
        min-width: fit-content;
    }

    a, picture {
        cursor: pointer;
        z-index: 100;
    }

    a {
        font-size: math.div(12rem, 14);
        line-height: 1;
        margin: 0 8px;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }

    img {
        display: block;
    }
}
