/* General style, Product Block/Slider style */
* {
    min-height: 0;
    min-width: 0;
}

#html-body {
    .slick-slider {
        flex-wrap: wrap;
    }

    .product-items.widget-product-carousel {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
        margin: 0;
    }

    .slick-dots {
        display: flex;
        justify-content: center;
        margin: 0;
        padding: 1rem 0;
        list-style-type: none;

        li {
            margin: 0 0.5rem;
        }

        button {
            display: block;
            width: 1rem;
            height: 1rem;
            padding: 0;
            border-radius: 100%;
            background-color: transparent;
            border: 1px solid #000;
            text-indent: -9999px;
        }

        li.slick-active {
            button {
                background-color: #000;
            }
        }
    }

    .slick-slide {
        .product-item-details {
            padding: 1rem;
            display: flex;
            flex-direction: column;
        }

        .product-item-inner {
            display: none;
        }

        .product-item-name {
            text-align: left;
        }
    }

    .slick-prev {
        left: -1rem;
        transform: rotate(180deg);
    }

    .slick-next {

        right: -1rem;
    }

    .slick-arrow {
        background: url('../images/arrow.svg');
        background-blend-mode: lighten;
        border: 0;
        border-radius: 50%;
        box-shadow: none;
        font-size: 0;
        height: 30px;
        margin: 2px 2px 0 0;
        position: absolute;
        top: 40%;
        width: 30px;

        &:hover {
            background: rgba(255, 255, 255, 0.3) url('../images/arrow.svg');
        }
    }

    .block-products-list.grid {
        .products-grid.grid {
            .product-item {
                width: 25%;
            }
        }
    }

    div[data-content-type="text"] {
        a {
            @include link-standard;
        }
    }

    div[data-content-type="row"] {
        > div {
            margin: 16px 0;
        }
    }

    .pagebuilder-column-group {
        .pagebuilder-column {
            margin: 0 8px;

            &:first-of-type {
                margin-left: 0;
            }

            &:last-of-type {
                margin-right: 0;
            }
        }
    }

    /* Buttons */
    .buttons-full-width {
        div[data-content-type="button-item"] {
            width: 100%;
        }
    }

    .buttons-rounded-corners {
        div[data-content-type="button-item"] span {
            border-radius: 60px;
            padding: 0 1.2rem;
        }
    }

    .buttons-large div[data-content-type="button-item"] span {
        padding: 0 2rem;
        height: 52px;
    }

    div[data-content-type="button-item"] {
        span {
            display: flex;
            align-items: center;
            height: 44px;
            font-size: 1rem;
            line-height: 1.2rem;
            justify-content: center;
            letter-spacing: 0.04em;
            text-transform: uppercase;
            font-weight: 400;
            flex-grow: 1;
            transition: 300ms ease all;
            padding: 0 1rem;
            color: $cblys-button__text-color;
            border: 0;
        }

        a:hover {
            text-decoration: none;
        }

        .pagebuilder-button-primary {
            span {
                background-color: $primary-button__background-color;
            }

            &:hover span {
                background-color: $primary-button__background-color__hover;
            }

            &:focus span,
            &:active span {
                background-color: $primary-button__background-color__focus;
            }
        }

        .pagebuilder-button-secondary {
            span {
                background-color: $secondary-button__background-color;
                border: 1px solid $cblys-button__border-color;
            }

            &:hover span {
                background-color: $secondary-button__background-color__hover;
                border: 1px solid transparent;
            }

            &:focus span,
            &:active span {
                background-color: $secondary-button__background-color__focus;
            }
        }

        .pagebuilder-button-link {
            span {
                background-color: $link-button__background-color;
            }

            &:hover span {
                background-color: $link-button__background-color__hover;
            }

            &:focus span,
            &:active span {
                background-color: $link-button__background-color__focus;
            }
        }

        .pagebuilder-button-tonal {
            span {
                background-color: $tonal-button__background-color;
            }

            &:hover span {
                background-color: $tonal-button__background-color__hover;
            }

            &:focus span,
            &:active span {
                background-color: $tonal-button__background-color__focus;
            }
        }
    }

    // Video - make sure all child divs respect minimum height given on the column in admin
    div[data-content-type="video"] {
        height: 100%;
        width: 100%;

        .pagebuilder-video-inner {
            height: 100%;

            .pagebuilder-video-wrapper {
                height: 100%;

                .pagebuilder-video-container {
                    height: 100%;

                    iframe {
                        height: 100%;
                        width: 100%;
                    }
                }
            }
        }
    }
}

.block-static-block.widget,
.block-cms-link.widget {
    margin: 0;
}

// Desktop + Tablet
@include min-screen($screen__m) {
    #html-body {
        .pagebuilder-mobile-only {
            display: none;
        }

        .pagebuilder-mobile-hidden {
            display: block;
        }
    }
}

// Mobile
@include max-screen($screen__m) {
    #html-body {
        .pagebuilder-mobile-only {
            display: block;
        }

        .pagebuilder-mobile-hidden {
            display: none;
        }

        .block-products-list.grid {
            .products-grid.grid {
                div.product-item {
                    width: 50%;
                }
            }
        }

        div[data-content-type="row"] {
            > div {
                margin: 8px 0;
            }
        }

        .mobile-full-width {
            .pagebuilder-column-group {
                .pagebuilder-column {
                    width: 100%;
                    margin: 8px;
                    min-height: unset !important; // overwrite inline style set in admin
                }
            }
        }

        .mobile-half-width {
            .pagebuilder-column-group {
                .pagebuilder-column {
                    margin: 8px;
                    width: calc(50% - 16px);
                    min-height: unset !important; // overwrite inline style set in admin
                }
            }
        }

        .pagebuilder-column-line, .pagebuilder-column-group {
            display: flex;
            flex-wrap: wrap;
        }

        .product-items,
        .pagebuilder-column-group {
            .slick-arrow {
                margin: 0;
                top: 37%;
                padding: 0.75rem;
                width: 32px;
                height: 32px;

                &::before {
                    margin: 0;
                    font-size: 0.8rem;
                    line-height: 1.2rem;
                }
            }

            .slick-prev {
                left: -0.5rem;
            }

            .slick-next {
                right: -0.5rem;
            }
        }
    }
}
