@use 'sass:math';

/**
*
* Colors
*
*/

$form-element-input__border-color: $color__gray !default;

/**
*
*  Globals
*
*/
$polarcore-cart-qty__color: #fabe58 !default;
$polarcore-value-cart__color: $primary__color !default;
$polarcore-primary-button__border: 0px solid #e5e5e5 !default;
$polarcore-primary-button__color: #fff !default;
$polarcore-primary-button__background: $polarcore-primary__color !default;

$polarcore-primary-cart-button__border: darken(#ff4203, 15%)  !default;
$polarcore-primary-cart-button__color: #000 !default;
$polarcore-primary-cart-button__background: #ff4203 !default;
$polarcore-primary-cart-button__box-shadow: 0 4px 0px 0px darken(#ff4203, 15%) !default;
$polarcore__border-color: #ededed !default;

$polarcore-button__border: 1px solid $polarcore__border-color;
$polarcore-top-links__color: $primary__color;
$text__color: $primary__color;


/**
*
* Form Element
*
*/

$form-element-input__height: 36px !default;
$form-element-input__border-color: $primary__color !default;
$form-element-input__border-radius: 0px !default;
$form-element-input__background: $color__gray !default;

/**
*
* Navigation
*
*/

$navigation__background: $secondary__color !default;
$navigation__level0-item-margin: 0 !default;
$navigation__item-color: $polarcore-secondary__color !default;
$navigation__item-color-hover: $polarcore-primary__color !default;
$navigation__item-color-active: $polarcore-primary__color !default;
$navigation__item-font-weight: 300 !default;
$navigation__item-background-hover: darken($navigation__background, 15%) !default;
$navigation__item-background-active: lighten($navigation__background, 5%) !default;
$navigation__item-border-color-active: darken($navigation__background, 15%) !default;
$navigation__item-border-width-active: 0 !default;

$navigation__level0-item-line-height: 30px !default; // Can't default may need to be moved

$navigation__submenu-background-hover: #f0f0f0 !default;
$navigation__submenu-border-color: rgba(0,0,0,0.05) !default;
$navigation__submenu-box-shadow: 0px 0px 20px rgba(0,0,0,0.15) !default;

$navigation__submenu-parent-icon: '\E145';
$navigation__submenu-parent-icon-position: right;
$navigation__submenu-parent-icon-color: #aaaaaa;
$navigation__submenu-parent-icon-size: 13px;

$navigation__submenu-arrow: true !default;
$navigation__submenu-arrow-size: 5px !default;
$navigation__submenu-arrow-left: 10px !default;

$polarcore-nav-sections__margin: 0 auto 0 0 !default; // margin-right: auto !default;

$polarcore-nav-sections-mobile__background-color: #f5f5f5 !default;
$polarcore-nav-sections-item-title__background: rgba(0,0,0,0.2) !default;
$polarcore-nav-sections-item-title-active__background: transparent !default;
$polarcore-nav-sections-item-title-link__color: #474747 !default;

$polarcore-navigation-link-mobile__color: #474747 !default;
$polarcore-nav-sections-item-switch-mobile__color: #474747 !default;

/**
*
* Modal
*
*/

$modal__box-shadow: none !default;

/**
*
* Page
*
*/


/**
*
* Pager
*
*/

$pager__line-height: $indent__l !default;
$pager__font-size: 14px !default;
$pager__icon-font-size: 32px !default;
$pager__icon-font-margin: 0 !default;

/**
*
* Filter
*
*/

$filter-title-background-color: $color-gray94 !default;
$filter-link: $color-gray-darken4 !default;
$filter-link-hover: darken($filter-link, 30%) !default;
$filter-quantity: $color-gray52 !default;

/**
*
* Checkout
*
*/

$checkout-sidebar__columns: 4 !default;

/**
*
* Header
*
*/

$header__background-color: $secondary__color !default; //v4
$header-panel__background-color: $color__gray !default; // "topline" v4
$header-panel__text-color: lighten($polarcore-secondary__color, 15%) !default; //v4
$polarcore-nav-toggle__color: #2a2a2a !default;
$polarcore-nav-toggle-hover__color: darken(#2a2a2a, 5%) !default;
$polarcore-nav-toggle-special__background: transparent !default;

$header-icons-color: #000 !default;
$header-icons-color-hover: lighten($polarcore-secondary__color, 2%) !default;
$minicart-icons-color: $header-icons-color !default;
$minicart-icons-color-hover: $header-icons-color-hover !default;

$polarcore-block-search-control__border: 1px solid $border-color__base !default;

/**
*
* Dropdown list
*
*/

$dropdown-list-pointer__border: #e2e2e2 !default; //????
$dropdown-list__background:  $polarcore-secondary__color !default;
$dropdown-list-item__hover: $color-gray91 !default;

/**
*
* Magento_Swatches module
*
*/

$polarcore-swatch-option-selected__border: 3px solid $polarcore-primary__color !default;
$polarcore-swatch-option-tooltip-image__border: 5px solid #f0f0f0 !default;

/**
*
* Footer
*
*/

$polarcore-footer__color: #f9f9f9 !default;

$footer__background-color: $polarcore-secondary__color !default;
$polarcore-footer__border-color: #666666;
$footer-links-color: $polarcore-footer__color !default;
$footer-links-color-hover: darken($polarcore-footer__color, 15%) !default;
$copyright__background-color: #1C1C1C !default;


/**
*
* Footer
*
*/

$polarcore-product-input-text-qty__border-radius: 0;

/**
*
*Toolbar
*
*/

$toolbar-mode-icon-font-size: 22px !default;
$toolbar-element-background: #ffffff !default;

// Luma Specific
$mobile-cart-padding: 15px !default;

// Potential new out-of-sync in blank/luma
$h1__margin-bottom: $heading__margin-bottom__base !default;
$account-title-border-color: $color-gray-middle2 !default;
$cart-price-color: $color-gray40 !default;
$dropdown-list-item__hover: $color-gray91 !default;

// Top Line
$font-size__top-line: 11px !default;

//  Search
$_search-advanced__display: none !default;

/*
 * Defined in styles/vendor/magento-ui/variables/_forms.scss
 * */

$form-element-input-type: ''; // [input-text|select|textarea|input-radio|input-checkbox]
$form-element-input__background: #ffffff;
$form-element-input__border-color: $cblys__black;
$form-element-input__border: 1px solid $form-element-input__border-color;
$form-element-input__border-radius: $cblys__border-radius;
$form-element-input__height: 52px;
$form-element-input__width: 100%;
$form-element-input__margin: 0.5rem 0.5rem;
$form-element-input__padding: 0.25rem 0.75rem;
$form-element-input__vertical-align: baseline;
$form-element-input__background-clip: padding-box; // [border-box|content-box|padding-box]
$form-element-input__font-size: $font-size__base;
$form-element-input__color: $cblys__darkgray;
$form-element-input__font-family: $font-family__base;
$form-element-input__font-weight: false;
$form-element-input__font-style: false;
$form-element-input__line-height: $line-height__base;

//  Placeholder
$form-element-input-placeholder__color: $cblys__darkgray;
$form-element-input-placeholder__font-style: $form-element-input__font-style;

//  Disabled state
$form-element-input__disabled__background: $form-element-input__background;
$form-element-input__disabled__border: $form-element-input__border;
$form-element-input__disabled__opacity: 0.5;
$form-element-input__disabled__color: $form-element-input__color;
$form-element-input__disabled__font-style: $form-element-input__font-style;

//  Focus state
$form-element-input__focus__background: $form-element-input__background;
$form-element-input__focus__border: $form-element-input__border;
$form-element-input__focus__color: $form-element-input__color;
$form-element-input__focus__font-style: $form-element-input__font-style;

//  Form elements choice default variables
$form-element-choice__type: ''; // [radio|checkbox]
$form-element-choice__vertical-align: false;
$form-element-choice__margin: 2px $indent__xs 0 0;
$form-element-choice__disabled__opacity: $form-element-input__disabled__opacity;

//
//  Input-text
//  ---------------------------------------------
$input-text__background: $form-element-input__background;
$input-text__border: $form-element-input__border;
$input-text__border-radius: $form-element-input__border-radius;
$input-text__height: $form-element-input__height;
$input-text__width: $form-element-input__width;
$input-text__margin: $form-element-input__margin;
$input-text__padding: $form-element-input__padding;
$input-text__vertical-align: $form-element-input__vertical-align;
$input-text__background-clip: $form-element-input__background-clip; // [border-box|content-box|padding-box]
$input-text__font-size: $form-element-input__font-size;
$input-text__color: $form-element-input__color;
$input-text__font-family: $form-element-input__font-family;
$input-text__font-weight: $form-element-input__font-weight;
$input-text__font-style: $form-element-input__font-style;
$input-text__line-height: $form-element-input__line-height;

//  Placeholder
$input-text-placeholder__color: $form-element-input-placeholder__color;
$input-text-placeholder__font-style: $form-element-input-placeholder__font-style;

//  Disabled state
$input-text__disabled__background: $form-element-input__disabled__background;
$input-text__disabled__border: $form-element-input__disabled__border;
$input-text__disabled__opacity: $form-element-input__disabled__opacity;
$input-text__disabled__color: $form-element-input__disabled__color;
$input-text__disabled__font-style: $form-element-input__disabled__font-style;

//  Focus state
$input-text__focus__background: $form-element-input__focus__background;
$input-text__focus__border: $form-element-input__focus__border;
$input-text__focus__color: $form-element-input__focus__color;
$input-text__focus__font-style: $form-element-input__focus__font-style;

//
//  Select
//  ---------------------------------------------

$select__background: $cblys__gray;
$select__border: 1px solid $cblys__gray;
$select__border-radius: $cblys__border-radius;
$select__height: 42px;
$select__width: $form-element-input__width;
$select__margin: 0;
$select__padding: $indent__xs $indent__s 4px;
$select__vertical-align: $form-element-input__vertical-align;
$select__background-clip: $form-element-input__background-clip; // [border-box|content-box|padding-box]
$select__font-size: $form-element-input__font-size;
$select__color: $cblys__black;
$select__font-family: $form-element-input__font-family;
$select__font-weight: $form-element-input__font-weight;
$select__font-style: $form-element-input__font-style;
$select__line-height: $form-element-input__line-height;

//  Placeholder
$select-placeholder__color: false;
$select-placeholder__font-style: false;

//  Disabled state
$select__disabled__background: $form-element-input__disabled__background;
$select__disabled__border: $form-element-input__disabled__border;
$select__disabled__opacity: $form-element-input__disabled__opacity;
$select__disabled__color: $form-element-input__disabled__color;
$select__disabled__font-style: $form-element-input__disabled__font-style;

//  Focus state
$select__focus__background: $cblys__gray;
$select__focus__border: 1px solid $cblys__gray;;
$select__focus__color: $cblys__black;
$select__focus__font-style: $form-element-input__focus__font-style;

//
//  Textarea
//  ---------------------------------------------

$textarea__background: $form-element-input__background;
$textarea__border: $form-element-input__border;
$textarea__border-radius: $form-element-input__border-radius;
$textarea__height: auto;
$textarea__width: $form-element-input__width;
$textarea__padding: $indent__s;
$textarea__margin: 0;
$textarea__vertical-align: $form-element-input__vertical-align;
$textarea__background-clip: $form-element-input__background-clip; // [border-box|content-box|padding-box]
$textarea__font-size: $form-element-input__font-size;
$textarea__color: $form-element-input__color;
$textarea__font-family: $form-element-input__font-family;
$textarea__font-weight: $form-element-input__font-weight;
$textarea__font-style: $form-element-input__font-style;
$textarea__line-height: $form-element-input__line-height;
$textarea__resize: vertical; // [none|both|horizontal|vertical|inherit]

//  Placeholder
$textarea-placeholder__color: $form-element-input-placeholder__color;
$textarea-placeholder__font-style: $form-element-input-placeholder__font-style;

//  Disabled state
$textarea__disabled__background: $form-element-input__disabled__background;
$textarea__disabled__border: $form-element-input__disabled__border;
$textarea__disabled__opacity: $form-element-input__disabled__opacity;
$textarea__disabled__color: $form-element-input__disabled__color;
$textarea__disabled__font-style: $form-element-input__disabled__font-style;

//  Focus state
$textarea__focus__background: $form-element-input__focus__background;
$textarea__focus__border: $form-element-input__focus__border;
$textarea__focus__color: $form-element-input__focus__color;
$textarea__focus__font-style: $form-element-input__focus__font-style;

//
//  Radio
//  ---------------------------------------------

$input-radio__vertical-align: $form-element-choice__vertical-align;
$input-radio__margin: $form-element-choice__margin;

$input-radio__disabled__opacity: $form-element-choice__disabled__opacity;

//
//  Checkbox
//  ---------------------------------------------

$input-checkbox__vertical-align: $form-element-choice__vertical-align;
$input-checkbox__margin: $form-element-choice__margin;

$input-checkbox__disabled__opacity: $form-element-choice__disabled__opacity;

//
//  Validation
//  ---------------------------------------------

$form-validation-note__color-error: $error__color;
$form-validation-note__font-size: $font-size__s;
$form-validation-note__font-family: inherit;
$form-validation-note__font-style: inherit;
$form-validation-note__font-weight: inherit;
$form-validation-note__line-height: inherit;
$form-validation-note__margin: 3px 0 0;
$form-validation-note__padding: inherit;

$form-validation-note-icon__use: false;
$form-validation-note-icon__font-content: $icon-pointer-up;
$form-validation-note-icon__font: $icon-font;
$form-validation-note-icon__font-size: $form-validation-note__font-size * 2;
$form-validation-note-icon__font-line-height: $form-validation-note__font-size;
$form-validation-note-icon__font-color: $form-validation-note__color-error;
$form-validation-note-icon__font-color-hover: inherit;
$form-validation-note-icon__font-color-active: inherit;
$form-validation-note-icon__font-margin: inherit;
$form-validation-note-icon__font-vertical-align: $icon-font__vertical-align;
$form-validation-note-icon__font-position: $icon-font__position;
$form-validation-note-icon__font-text-hide: $icon-font__text-hide;

$form-element-validation__color-error: false;
$form-element-validation__color-valid: false;
$form-element-validation__border-error: lighten($form-validation-note__color-error, 20%);
$form-element-validation__border-valid: false;
$form-element-validation__background-error: false;
$form-element-validation__background-valid: false;

//
//  Fieldset
//  ---------------------------------------------

$form-fieldset__border: 0;
$form-fieldset__margin: 0 0 $indent__xl;
$form-fieldset__padding: 0;
$form-fieldset-legend__color: inherit;
$form-fieldset-legend__font-size: 20px;
$form-fieldset-legend__font-family: inherit;
$form-fieldset-legend__font-weight: inherit;
$form-fieldset-legend__font-style: inherit;
$form-fieldset-legend__line-height: 1.2;
$form-fieldset-legend__margin: 0 0 $indent__m;
$form-fieldset-legend__padding: 0;
$form-fieldset-legend__width: inherit;

//
//  Field
//  ---------------------------------------------

$form-field-type: block; // [inline|block]
$form-field-type-revert: inline; // [inline|block|false]
$form-field__border: none;
$form-field__vertical-indent: $indent__base;
$form-field__additional-vertical-indent: math.div($form-field__vertical-indent, 2);
$form-field-type-block__margin: 0 0 $form-field__vertical-indent;
$form-field-type-inline__margin: 0 0 $form-field__vertical-indent;

$form-field-column: inherit;
$form-field-column__padding: 0 12px 0 0;
$form-field-column__number: 2;

//  Form field label
$form-field-label__align: false;
$form-field-label__color: inherit;
$form-field-label__font-size: inherit;
$form-field-label__font-family: inherit;
$form-field-label__font-weight: $font-weight__bold;
$form-field-label__font-style: inherit;
$form-field-label__line-height: inherit;

$form-field-type-label-inline__margin: 0 0 $indent__base;
$form-field-type-label-inline__padding-top: 6px;
$form-field-type-label-inline__padding: $form-field-type-label-inline__padding-top 15px 0 0;
$form-field-type-label-inline__width: 0;
$form-field-type-label-inline__align: right;

$form-field-type-label-block__margin: 0 0 $indent__xs;
$form-field-type-label-block__padding: inherit;
$form-field-type-label-block__align: inherit;

//  Form field control
$form-field-type-control-inline__width: 74.2%;

//  Form field label asterisk
$form-field-label-asterisk__color: $color-red10;
$form-field-label-asterisk__font-size: $font-size__s;
$form-field-label-asterisk__font-family: inherit;
$form-field-label-asterisk__font-weight: inherit;
$form-field-label-asterisk__font-style: inherit;
$form-field-label-asterisk__line-height: inherit;
$form-field-label-asterisk__margin: 0 0 0 $indent__xs;

//  Form field note
$form-field-note__color: $error__color;
$form-field-note__font-size: $font-size__s;
$form-field-note__font-family: inherit;
$form-field-note__font-weight: inherit;
$form-field-note__font-style: inherit;
$form-field-note__line-height: inherit;
$form-field-note__margin: 3px 0 0;
$form-field-note__padding: 0;

//  Form field note icon
$form-field-note-icon-font: $icon-font;
$form-field-note-icon-font__content: $icon-pointer-up;
$form-field-note-icon-font__size: $form-field-note__font-size * 2;
$form-field-note-icon-font__line-height: $form-field-note__font-size;
$form-field-note-icon-font__color: $form-field-note__color;
$form-field-note-icon-font__color-hover: inherit;
$form-field-note-icon-font__color-active: inherit;
$form-field-note-icon-font__margin: inherit;
$form-field-note-icon-font__vertical-align: $icon-font__vertical-align;
$form-field-note-icon-font__position: $icon-font__position;
$form-field-note-icon-font__text-hide: $icon-font__text-hide;

//  Hasrequired
$form-hasrequired__position: top; // [top|bottom]
$form-hasrequired__color: $form-field-label-asterisk__color;
$form-hasrequired__font-size: $font-size__s;
$form-hasrequired__font-family: inherit;
$form-hasrequired__font-weight: inherit;
$form-hasrequired__font-style: inherit;
$form-hasrequired__line-height: inherit;
$form-hasrequired__border: none;
$form-hasrequired__margin: $indent__s 0 0;
$form-hasrequired__padding: inherit;

//
//  Sections variables
//  _____________________________________________

//
//  Tabs
//  ---------------------------------------------

//  Tabs controls

$tab-control__font-family: inherit !default;
$tab-control__font-style: inherit !default;
$tab-control__height: $indent__base !default;

$tab-control__padding-top: $indent__xs !default;
$tab-control__padding-bottom: $indent__xs !default;
$tab-control__padding-right: $indent__base !default;
$tab-control__padding-left: $tab-control__padding-right !default;
$tab-control__border-color: transparent;
$tab-control__border-width: 0 !default;
$tab-control__background-color: $cblys__gray;
$tab-control__active__background-color: $cblys__gray;

//  Current tab
$tab-control__background-color: $cblys__gray !default;
$tab-control__color: $text__color__muted !default;
$tab-control__hover__color: $cblys__black !default;
$tab-control__hover__background-color: $cblys__gray !default;
$tab-control__text-decoration: none !default;
$tab-control__hover__text-decoration: $tab-control__text-decoration !default;
$tab-control__active__background-color: $cblys__gray !default;
$tab-control-font-weight: 400;

$tab-content__border: 0  !default;
$tab-content__margin-top: 0;

$tab-content__padding-top: $indent__base !default;
$tab-content__padding-right: $indent__base !default;
$tab-content__padding-bottom: $indent__base !default;
$tab-content__padding-left: $indent__base !default;

$accordion-control__padding-top: $tab-control__padding-top !default;
$accordion-control__padding-right: $indent__base !default;
$accordion-control__padding-bottom: $tab-control__padding-bottom !default;
$accordion-control__padding-left: $indent__base !default;
