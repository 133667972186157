@use 'sass:math';

.login-box {
    color: white;
    background-color: $cblys__darkbrown-shade;
    border: none;
    border-radius: 0;
    float: none;
    margin: 0 -1rem;
    padding: 16px 24px;

    .login-title {
        margin: 0 0 4px 0;
    }

    .login-text {
        font-size: math.div(16rem, 14);
        letter-spacing: 0.5px;
        line-height: 1.75;
    }

    .login-benefits {
        margin: 12px 0;

        .title {
            cursor: pointer;

            @include lib-icon-font(
                    $icon-down,
                $_icon-font-size: 24px,
                $_icon-font-position: after,
                $_icon-font-line-height: 1,
                $_icon-font-color: inherit
            );

            .less {
                display: none;
            }
        }

        .content {
            display: none;
            letter-spacing: 0.25px;

            ul, p {
                margin: 12px 0;
            }

            ul {
                list-style-type: none;
                padding: 0;

                li {
                    align-items: center;
                    display: flex;
                    margin: 4px 0;

                    img {
                        margin: 0 8px 0 4px;
                    }
                }
            }
        }

        &.active {
            .title {
                @include lib-icon-font(
                        $icon-up,
                    $_icon-font-size: 24px,
                    $_icon-font-position: after,
                    $_icon-font-line-height: 1,
                    $_icon-font-color: inherit
                );

                .less {
                    display: inline;
                }

                .more {
                    display: none;
                }
            }
        }
    }

    .actions-toolbar {
        display: flex;
        margin: 0;
        padding: 8px 0 0 0;

        a, button {
            white-space: nowrap;
        }

        .new-customer-link {
            @include cblys-re-green-btn(
                $_cblys_button-border-radius: $border-radius__l,
                $_cblys_button-line-height: 1.43,
                $_cblys_button-letter-spacing: 0.4px,
                $_cblys_button-width: 50%,
                $_cblys_button-margin: 0 8px 0 0
            );
            flex-grow: 0;
        }

        .action-auth-toggle {
            @include cblys-beige-button(
                $_cblys_button-border-radius: $border-radius__l,
                $_cblys_button-line-height: 1.43,
                $_cblys_button-letter-spacing: 0.4px,
                $_cblys_button-width: 50%,
                $_cblys_button-margin: 0 0 0 8px
            );
        }
    }
}

@include min-screen($screen__m) {
    .login-box {
        background-color: transparent;
        border: 2px solid $cblys__beige-active;
        border-radius: $border-radius__s;
        color: inherit;
        margin: 24px 0 0 0;

        .actions-toolbar {
            .new-customer-link {
                @include cblys-beige-button(
                    $_cblys_button-border-radius: $border-radius__l,
                    $_cblys_button-line-height: 1.43,
                    $_cblys_button-letter-spacing: 0.4px,
                    $_cblys_button-width: 50%,
                    $_cblys_button-margin: 0 8px 0 0
                );
                flex-grow: 0;
            }

            .action-auth-toggle {
                @include cblys-base-button(
                    $_cblys_button-border-radius: $border-radius__l,
                    $_cblys_button-line-height: 1.43,
                    $_cblys_button-letter-spacing: 0.4px,
                    $_cblys_button-width: 50%,
                    $_cblys_button-margin: 0 0 0 8px
                );
            }
        }
    }
}
