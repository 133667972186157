//
//  Common
//  _____________________________________________
html {
    scroll-behavior: smooth;
}

body {
    background-color: $page__background-color;
}

#maincontent {
    background: none;
}

.page-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    .ie9 & {
        background-color: $page__background-color;
        min-height: 0;
    }
}

//
//  Header
//  ---------------------------------------------

.page-header {
    background-color: $page__background-color;
    border-bottom: 1px solid $border-color__base;
    margin-bottom: 0;

    .panel.wrapper {
        background-color: $header-panel__background-color;
        color: $header-panel__text-color;
    }
}

.header.panel {
    > .header.links {
        @include lib-list-inline();
        float: right;
        font-size: 0;
        margin-right: $indent__base;

        > li {
            font-size: $font-size__top-line;
            margin: 0 0 0 15px;

            > a {
                @include lib-link(
                    $_link-color: $header-panel__text-color,
                    $_link-text-decoration: none,
                    $_link-color-visited: $header-panel__text-color,
                    $_link-text-decoration-visited: none,
                    $_link-color-hover: $header-panel__text-color,
                    $_link-text-decoration-hover: underline,
                    $_link-color-active: $header-panel__text-color,
                    $_link-text-decoration-active: underline
                );
            }
        }
    }
}

.logo {
    max-width: 50%;
    position: relative;
    z-index: 5;

    img {
        display: block;
    }

    .page-print & {
        display: inline-block;
        float: none;
    }
}

.page-main {
    background-color: $page-main__background-color;
    margin-bottom: 2rem;
    > .page-title-wrapper {
        padding: 0 $layout-indent__width;
        .page-title + .action {
            margin-top: $indent__l;
        }
    }
}

.action.skip {
    &:not(:focus) {
        @extend .abs-visually-hidden;
    }

    &:focus {
        background: $color-gray94;
        padding: $indent__s;
        box-sizing: border-box;
        left: 0;
        position: absolute;
        text-align: center;
        top: 0;
        width: 100%;
        z-index: 15;
    }
}

//
//  Global notice
//  ---------------------------------------------

.message.global {
    p {
        margin: 0;
    }

    &.noscript,
    &.cookie {
        @include lib-message($_message-type: global-note);
        margin: 0;
    }

    &.cookie {
        bottom: 0;
        left: 0;
        position: fixed;
        right: 0;
        z-index: 3;
        background-color: $cblys__gray;
        font-size: 16px;
        color: $cblys__black;
        text-align: center;
        padding: 20px;

        .actions {
            margin: 20px auto 0 auto;
            max-width: 175px;

            button.primary {
                @include cblys-re-brown-btn;
                text-transform: none;
                font-size: 16px;
            }
        }
    }

    &.demo {
        @include lib-message($_message-type: global-caution);
        margin-bottom: 0;
        text-align: center;
    }
}

.messages .to-cart-link {
    text-decoration: underline;
}

//
//  Footer
//  ---------------------------------------------

.page-footer {
    margin-top: auto;
}

.footer {
    &.content {
        border-top: none;
        padding-top: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        ul {
            @extend .abs-reset-list;
        }
    }
}

.copyright {
    background-color: $copyright__background-color;
    color: $color-black;
    display: block;
    padding: $indent__s;
    text-align: center;
}

//
//  Widgets
//  ---------------------------------------------

.sidebar {
    .widget.block:not(:last-child),
    .widget:not(:last-child) {
        margin-bottom: $indent__xl;
    }
}

.widget {
    clear: both;

    .block-title {
        @extend .abs-block-widget-title;
    }
}

.page-header,
.page-footer {
    .widget.block {
        margin: $indent__base 0;
    }
}

.no-display {
    @extend .abs-no-display;
}

//
//  Calendar
//  ---------------------------------------------

.ui-datepicker td {
    padding: 0;
}

.ui-tooltip {
    background: $tooltip__background;
    border: $tooltip__border-width solid $tooltip__border-color;
    padding: 10px;
}

//
//  Navigation
//  _____________________________________________

@include max-screen($screen__m) {
    .customer-name,
    .customer-welcome + .authorization-link {
        display: none;
    }

    .nav-sections .switcher-trigger strong:after {
        font-size: $font-size__base;
        right: -25px;
        top: -2px;
    }

    .navigation .parent .level-top:after {
        font-size: $font-size__base;
        right: 10px;
        top: 0px;
    }

    .page-footer .footer.content {
        .switcher-store {
            background: $footer__background-color;
            font-size: $font-size__base;
            margin: 0 (-$layout__width-xs-indent);

            .switcher-options {
                display: block;

                ul.dropdown {
                    background: none;
                    border: 0;
                    box-shadow: none;
                    margin: 0;
                    position: relative;

                    &:before,
                    &:after {
                        display: none;
                    }
                }

                .switcher-trigger,
                .switcher-option {
                    border-top: 1px solid $footer-links-separator-border-color;
                    display: block;
                    padding: $indent__s $layout-indent__width;
                }

                .switcher-trigger strong {
                    padding: $indent__s 0;
                }

                .switcher-option a {
                    padding: 0;
                }
            }
        }
    }
}

@include max-screen($screen__s) {
    .logo {
        margin-bottom: 13px;
        margin-top: 4px;
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    html,
    body {
        height: 100%; // Stretch screen area for sticky footer
    }

    body {
        .ie9 & {
            background-color: $copyright__background-color;
        }
    }

    .navigation ul {
        padding: 0 8px;
        display: flex;
        justify-content: space-between;
        text-transform: uppercase;
    }

    .page-header {
        border: 0;
        margin-bottom: 0;

        .panel.wrapper {
            border-bottom: $header-panel__border-width solid
                $header-panel__border-color;
            background-color: $header-panel__background-color;
        }

        .header.panel {
            @extend .abs-add-clearfix-desktop;
            padding-bottom: 10px;
            padding-top: 10px;
            flex-direction: row-reverse;
            padding-left: 0;
        }

        .switcher {
            float: right;
            margin-left: 15px;
            margin-right: -6px;
            order: 1;
        }
    }

    .page-main {
        > .page-title-wrapper {
            padding: 0;
            .page-title {
                display: inline-block;
                z-index: 1;
                position: relative;
            }

            .page-title + .action {
                float: right;
                margin-top: $indent__base;
            }
        }
    }

    .customer-welcome {
        @include lib-dropdown(
            $_toggle-selector: ".action.switch",
            $_options-selector: "ul",
            $_dropdown-actions-padding: 0,
            $_dropdown-list-item-padding: 0,
            $_dropdown-toggle-icon-content: $icon-down,
            $_dropdown-toggle-active-icon-content: $icon-up,
            $_icon-font-text-hide: true,
            $_icon-font-size: 10px,
            $_icon-font-line-height: 22px,
            $_dropdown-list-pointer-position: right,
            $_dropdown-list-position-right: -8px,
            $_dropdown-list-z-index: $customer-welcome__z-index
        );

        li {
            // @extend .switcher li;
            a {
                @include lib-link(
                    $_link-color: $color-gray20,
                    $_link-text-decoration: none,
                    $_link-color-visited: $color-gray20,
                    $_link-text-decoration-visited: none,
                    $_link-color-hover: $color-gray20,
                    $_link-text-decoration-hover: none,
                    $_link-color-active: $color-gray20,
                    $_link-text-decoration-active: none
                );
                display: block;
                line-height: 1.4;
                padding: 8px;
            }
        }

        .customer-name {
            cursor: pointer;
            display: flex;
            flex-direction: row;
            white-space: nowrap;
        }

        .customer-menu {
            display: none;
        }

        .action.switch {
            @include lib-button-reset();
            color: $color-white;
        }

        .header.links {
            min-width: 175px;
        }

        &.active {
            .action.switch {
                @include lib-icon-font-symbol(
                    $_icon-font-content: $icon-up,
                    $_icon-font-position: before
                );
            }

            .customer-menu {
                display: block;
            }
        }

        .greet {
            display: none;
        }
    }

    .header {
        &.panel {
            > .header.links {
                @include lib-list-inline();
                float: right;
                display: block;
                min-width: 210px;
                text-align: right;

                > li {
                    margin: 0 15px 0 0;

                    &.welcome {
                        a {
                            color: $color-white;
                            padding-left: $indent__xs;
                        }
                    }
                }

                > .authorization-link {
                    &:after {
                        content: attr(data-label);
                    }
                }

                > .customer-welcome + .authorization-link {
                    display: none;
                }
            }
        }

        &.content {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
        }
    }

    .logo {
        img {
            max-height: inherit;
        }
    }

    .page-footer {
        background: $footer__background-color;
        margin-top: auto;
        padding-bottom: 0;

        .switcher {
            .options {
                ul.dropdown {
                    bottom: -$indent__s;
                    left: 100%;
                    margin: 0 0 0 $indent__base;
                    top: auto;

                    &:before,
                    &:after {
                        bottom: $indent__base - 7px;
                        left: auto;
                        right: 100%;
                        top: auto;
                    }

                    &:before {
                        border-color: transparent $dropdown-list__background
                            transparent transparent;
                    }

                    &:after {
                        border-color: transparent $dropdown-list-pointer__border
                            transparent transparent;
                        margin: 0 0 -1px -1px;
                    }
                }
            }
        }
    }

    .footer {
        &.content {
            border-top: none;
            max-width: none;

            .switcher.store {
                display: block;
                margin: 0;
            }

            .links {
                display: inline-block;
                padding-right: 50px;
                vertical-align: top;

                li {
                    background: transparent;
                    border: none;
                    font-size: 14px;
                    margin: 0 0 8px;
                    padding: 0;
                }

                a,
                strong {
                    display: inline;
                }
            }
        }
    }
}
