@mixin cblys-base-button(
    $_cblys--button__background: $cblys__white,
    $_cblys-button__border-color: $cblys__black,
    $_cblys-button__color: $cblys__black,
    $_cblys-button__icon: "",
    $_cblys-button__padding: 10px 2rem,
    $_cblys-button__states: "",
    $_cblys_button-border-radius: 0,
    $_cblys_button-line-height: 17px,
    $_cblys_button-letter-spacing: 0.1em,
    $_cblys_button-width: auto,
    $_cblys_button-margin: 0,
) {
    @include lib-button(
        $_button-border: 1px solid $_cblys-button__border-color,
        $_button-color: $_cblys-button__color,
        $_button-color-hover: $_cblys-button__color,
        $_button-color-active: $_cblys-button__color,
        $_button-border-hover: 1px solid $_cblys-button__border-color,
        $_button-border-active: 1px solid $_cblys-button__border-color,
        $_button-background-hover: $_cblys--button__background,
        $_button-background-active: $_cblys--button__background,
        $_button-background: $_cblys--button__background,
        $_button-padding: $_cblys-button__padding,
        $_button_display: flex,
        $_button-border-radius: $_cblys_button-border-radius,
        $_button-width: $_cblys_button-width,
        $_button-margin: $_cblys_button-margin,
    );
    font-size: 1rem;
    line-height: $_cblys_button-line-height;
    align-items: center;
    justify-content: center;
    letter-spacing: $_cblys_button-letter-spacing;
    text-transform: uppercase;
    font-weight: 400;
    flex-grow: 1;
    transition: 0.2s;

    span {
        flex-grow: 1;
    }
    @if ($_cblys-button__states == "") {
        &:hover {
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            text-decoration: none;
        }

        &:active,
        &:focus {
            box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.15);
        }
    }

    &:active,
    &:focus {
        border: 1px solid $_cblys-button__border-color;
    }

    @if ($_cblys-button__icon != "") {
        &:before {
            @include material-icon($_cblys-button__icon);
        }
    }
}

@mixin cblys-white-button() {
    @include cblys-base-button();
}

@mixin cblys-blue-button() {
    @include cblys-base-button(
        $_cblys--button__background: $cblys__light-blue,
        $_cblys-button__color: $cblys__black,
        $_cblys-button__border-color: $cblys__light-blue
    );
}

@mixin cblys-black-button() {
    @include cblys-base-button(
        $_cblys--button__background: $cblys__black,
        $_cblys-button__color: $cblys__white,
        $_cblys-button__border-color: $cblys__black
    );
}

@mixin cblys-gray-button() {
    @include cblys-base-button(
        $_cblys--button__background: $cblys__gray,
        $_cblys-button__color: $cblys__black,
        $_cblys-button__border-color: $cblys__gray
    );
}

@mixin cblys-white-blue-button() {
    @include cblys-base-button(
        $_cblys-button__color: $cblys__light-blue,
        $_cblys-button__border-color: $cblys__white
    );
}

@mixin cblys-transparent-button() {
    @include cblys-base-button(
        $_cblys--button__background: transparent,
        $_cblys-button__color: $cblys__black,
        $_cblys-button__border-color: $cblys__black
    );
}

@mixin cblys-beige-button(
    $args...
) {
    @include cblys-base-button(
        $_cblys--button__background: $tonal-button__background-color,
        $_cblys-button__color: $cblys__black,
        $_cblys-button__border-color: $tonal-button__background-color,
        $args...
    );

    &:hover {
        background-color: $tonal-button__background-color__hover;
        box-shadow: none;
    }

    &:focus,
    &:active {
        background-color: $tonal-button__background-color__focus;
        border: none;
    }
}

// CBLYS-449 - Update visual profile
// Added re- prefix to scope to new redesigned styles and avoid conflicts
@mixin cblys-re-green-btn ($args...) {
    @include cblys-base-button(
        $_cblys--button__background: $cblys__re-light-green,
        $_cblys-button__color: $cblys__black,
        $args...
    );
    border: 0;

    &:hover {
        box-shadow: none;
        background: $cblys__re-lighter-green;
    }

    &:active,
    &:focus {
        box-shadow: none;
        background: $cblys__re-green;
        border: 0;
    }
}

@mixin cblys-re-outline-brown-btn {
    @include cblys-base-button(
        $_cblys--button__background: transparent,
        $_cblys-button__color: $cblys__re-light-brown,
        $_cblys-button__border-color: $cblys__re-light-brown
    );

    &:hover {
        box-shadow: none;
        background: $cblys__re-lighter-brown;
        color: $cblys__re-lighter-brown;
    }

    &:active,
    &:focus {
        box-shadow: none;
        background: $cblys__re-brown;
    }
}

@mixin cblys-re-brown-btn {
    @include cblys-base-button(
        $_cblys--button__background: $cblys__re-light-brown,
        $_cblys-button__color: $cblys__white,
        $_cblys-button__border-color: $cblys__re-light-brown
    );

    &:hover {
        box-shadow: none;
        background: $cblys__re-lighter-brown;
    }

    &:active,
    &:focus {
        box-shadow: none;
        background: $cblys__re-brown;
    }
}

@mixin cblys-re-black-btn {
    @include cblys-base-button(
        $_cblys--button__background: $cblys__black,
        $_cblys-button__color: $cblys__white
    );
    border: 0;

    &:hover {
        box-shadow: none;
        background: $cblys__black;
        color: $cblys__white;
    }

    &:active,
    &:focus {
        box-shadow: none;
        background: $cblys__black;
        border: 0;
    }
}
// End of redesign styles

@mixin cblys-cart-button() {
    @include cblys-re-green-btn;
    display: flex;
    height: 60px;
}

@mixin cblys-click-collect-button {
    @include cblys-re-outline-brown-btn;
    &:before {
        @include material-icon("store");
    }
}

@mixin cblys-close-button() {
    @include lib-icon-font(
        $icon-remove,
        $_icon-font-size: 16px,
        $_icon-font-line-height: 20px
    );
    font-weight: bolder;
    position: relative;
    margin-left: auto;
    display: flex;
    flex-flow: row-reverse;
    width: auto;
    align-items: center;
    & > span {
        display: none;
    }
}

@mixin cblys-menu-button() {
    @include cblys-base-button(
        $_cblys-button__color: $primary__color,
        $_cblys-button__border-color: $cblys__gray,
        $_cblys--button__background: $cblys__gray,
        $_cblys-button__padding: 0
    );
    margin: 5px 0;
    width: auto;
    height: 36px;
    justify-content: left;
    width: auto;
}

@mixin cblys-swatch-button() {
    @include cblys-gray-button();
    margin: 5px;
    padding: 10px;
    flex-grow: 0;
    border: 3px solid $cblys__gray;
    &:active,
    &:focus {
        box-shadow: none;
        border-width: 3px;
    }
}

.action.primary,
input[type="button"],
input[type="reset"],
input[type="submit"],
button {
    @include cblys-base-button();
}

.mdl-button {
    @include cblys-blue-button();
}

.action.primary.tocart {
    @include cblys-cart-button();
}

/* STOREFINDER BUTTONS */

.app-container {
    .location-button-container {
        @include cblys-re-green-btn;
    }

    .store-list-item-button {
        @include cblys-re-green-btn;
        margin: 5px 0;
    }

    .locality-list-item {
        @include cblys-base-button(
            $_cblys--button__background: $cblys__white,
            $_cblys-button__border-color: $cblys__black,
            $_cblys-button__color: $cblys__black,
            $_cblys-button__icon: "",
            $_cblys-button__padding: 10px 0
        );
        box-shadow: none;
    }
}

.product-item-qty button {
    position: absolute;
    top: $layout-indent__width * 3;
    padding: 5px 10px;
    font-size: 12px;
}

body .plus-button {
    width: 44px;
    height: 44px;
    background: transparent;
    border: none;
    padding: 0;
    flex-grow: 0;

    &:hover,
    &:active,
    &:focus {
        box-shadow: none;
        border: none;
    }

    .circle-fill {
        fill: $cblys__pear;
    }

    &:hover .circle-fill {
        fill: $cblys__re-lighter-green;
    }

    &:active,
    &:focus {
        .circle-fill {
            fill: $cblys__pear-shade;
        }
    }
}
